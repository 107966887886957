import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderInterceptor } from './core/Interceptor/LoaderInterceptor';
import { AgmCoreModule } from '@agm/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { HeaderComponent } from './pages/_layout/components/header/header.component';
import { HeaderMenuComponent } from './pages/_layout/components/header/header-menu/header-menu.component';
import { FooterComponent } from './pages/_layout/components/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguageSelectorComponent } from './pages/_layout/components/header/language-selector/language-selector.component';
import { LayoutComponent } from './pages/_layout/layout.component';
import { PageRoutingModule } from './pages/page-routing.module';
import { CommonModule } from '@angular/common';
import { FooterModule } from './pages/_layout/components/footer/footer.module';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';

//import { NgxSliderModule } from '@angular-slider/ngx-slider';
const API_ROOT_URL = `${environment.apiUrl}`;

export function rootLoaderFactory(http: HttpClient){


  //return new TranslateHttpLoader(http, 'http://imoapi.qr-dine.com/api/Resourse/language?lanId=', '');
  
  return new TranslateHttpLoader(http, 'https://api.immotrade365.com/api/Resourse/language?lanId=', '');
  //return new TranslateHttpLoader(http, 'https://localhost:44365/api/Resourse/language?lanId=', '');
  

}
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    LanguageSelectorComponent,
    HeaderMenuComponent,
    
    //AlertComponent
    //FooterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    PageRoutingModule,
    CommonModule,
    FooterModule,
    SocialLoginModule,
   
   TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: rootLoaderFactory,
          deps: [HttpClient]
        }
      }
    ),
    NgbModule,

    // AgmCoreModule.forRoot({
    //   apiKey: '',
    //   libraries: ['places']
    // }),
  ],
  exports:[TranslateModule],
  providers: [ {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            '641407372812-kne73uobmb7epv89e6frpb6iiio14anq.apps.googleusercontent.com'
          )
        },
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider('3150541045219613')
        }
      ]
    } as SocialAuthServiceConfig,
  },
    {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
