import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterLinkActive, RouterModule } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import { LayoutService } from 'src/app/core/services/GlobalServices/layout.service';
import { PropertylistService } from 'src/app/core/services/PageServices/propertylist.service';
import {filter} from 'rxjs/operators';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  // tslint:disable-next-line: variable-name
  isShowDiv:boolean= false;
  searchUrl:any;
  isTransparent:boolean;
  constructor(private layoutService: LayoutService,private router: Router) { 
this.isTransparent=false;
// this.searchUrl=window.location.href.split('/#/')[1]; 
// console.log("URL",this. searchUrl)
                this.layoutService.isTransparent.subscribe(q=>{
                  this.isTransparent=q;
                })
              }
 // @ViewChild(RouterLinkActive) private rla: RouterLinkActive;
  ngOnInit(): void {
  this.layoutService.isTransparent.subscribe((q: boolean) => {
      this.isTransparent = q;
    });
   // this.searchUrl=window.location.href.split('/#/')[1];
    
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => 
     {
      
        this.searchUrl = event;          
        console.log("URL",this.searchUrl.url);
     });
    
   //console.log("URL",this.searchUrl)
    //this.clickHomeMenu();
  }
  toggleDisplayDiv() { 
    this.isShowDiv = true;  
    this.layoutService.IsShowDiv.next(this.isShowDiv);
  }  
}
