import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VMSearch {
  private dynamicAttribute: Array<Number>;
  lat: number;
  lng: number;
  MNRooms: string;
  MXRooms: string;
  Price: string;
  IsRent: boolean;
  Attributes: [];
  PropFeatureAttributes: [];
  PropFurnshingAttributes: number;
  Location: string;
  BuyRent: string;
  Polygon: string;
  MNSize: string;
  MXSize: any;
  Floor: string;
  SPrice: string;
  EPrice: string;
  Images: boolean;
  Prices: boolean;
  setDynamicAttribute() {
    this.dynamicAttribute=[];
    this.Attributes.forEach((selAtt:any )=> {
      this.dynamicAttribute.push(selAtt.ID)
    })
    this.PropFeatureAttributes.forEach((selAtt:any )=> {
      this.dynamicAttribute.push(selAtt.ID)
    })
    // this.PropFurnshingAttributes.forEach((selAtt:any )=> {
    //   this.dynamicAttribute.push(selAtt.ID)
    // })
    if(this.PropFurnshingAttributes != 0){
      var kk=this.PropFurnshingAttributes.toString()
      this.dynamicAttribute.push(parseInt(kk))
    }
  }
  getAttributes(): string{
    return this.dynamicAttribute.join();
  }
  public constructor() {
    this.lat = 0;
    this.lng = 0;
    this.MNRooms = "0";
    this.MXRooms = "-1";
    this.Price = '0';
    this.IsRent = false;
    this.Attributes = [];
    this.PropFeatureAttributes=[];
    this.PropFurnshingAttributes=0;
    this.dynamicAttribute=[];
    this.Location = '';
    this.BuyRent = "0";
    this.Polygon = '';
    this.MNSize = "0";
    this.MXSize = "-1";
    this.Floor = "0";
    this.SPrice = "0";
    this.EPrice = "5000000";
    this.Images=false;
    this.Prices=false;
  }
  public setValue(_VMSearch: VMSearch) {
    this.Attributes = _VMSearch.Attributes;
    this.PropFeatureAttributes = _VMSearch.PropFeatureAttributes;
    this.PropFurnshingAttributes = _VMSearch.PropFurnshingAttributes;
    //this.dynamicAttribute = _VMSearch.dynamicAttribute;
    this.lat = _VMSearch.lat;
    this.lng = _VMSearch.lng;
    this.MNRooms = _VMSearch.MNRooms;
    this.MXRooms = _VMSearch.MXRooms;
    this.Price = _VMSearch.Price;
    this.IsRent = _VMSearch.IsRent;
    this.PropFeatureAttributes = _VMSearch.PropFeatureAttributes;
    this.PropFurnshingAttributes = _VMSearch.PropFurnshingAttributes;
    this.Location = _VMSearch.Location;
    this.BuyRent = _VMSearch.BuyRent;
    this.Polygon = _VMSearch.Polygon;
    this.MNSize = _VMSearch.MNSize;
    this.MXSize = _VMSearch.MXSize;
    this.Floor = _VMSearch.Floor;
    this.SPrice = _VMSearch.SPrice;
    this.EPrice = _VMSearch.EPrice;
    this.Images = _VMSearch.Images;
    this.Prices = _VMSearch.Prices;
    this.setDynamicAttribute();
  }

}

// export class VMSearchAdvance {
//   lat: number;
//   lng: number;
//   Rooms: string;
//   Price: string;
//   IsRent: boolean;
//   Attributes: string;
//   Location: string;
//   BuyRent: string;
//   Polygon: string;
//   public constructor() {
//     this.lat = 0;
//     this.lng = 0;
//     this.Rooms = "0";
//     this.Price = '0';
//     this.IsRent = false;
//     this.Attributes = '';
//     this.Location = '';
//     this.BuyRent = "0";
//     this.Polygon = '';
//   }
// }
