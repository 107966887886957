import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthHttpService } from '../core/services/AuthServices/auth-http.service';
import { PropertydetailsComponent } from './propertydetails/propertydetails.component';
import { LayoutComponent } from './_layout/layout.component';



const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'prop',
        loadChildren: () =>
          import('./propertylist/propertylist.module').then((m) => m.PropertylistModule),
      },
      {
        path: 'propertydetails', component: PropertydetailsComponent 
      //  path: 'property-details',
      //  loadChildren: () =>
      //    import('./propertylist/modals/proparty-detail-modal/proparty-detail-modal.module').then((m) => m.PropartyDetailModalModule),
       
       },
      {
        path: 'about',
        loadChildren: () =>
          import('./about/about.module').then((m) => m.AboutModule),
      },
      {
        path: 'support',
        loadChildren: () =>
          import('./support/support.module').then((m) => m.SupportModule),
      },
      {
        path: 'advertise',
        loadChildren: () =>
          import('./advertise/advertise.module').then((m) => m.AdvertiseModule),
      },
      // {
      //   path: 'support',
      //   loadChildren: () =>
      //     import('./support/support.module').then((m) => m.SupportModule),
      // },
      {
        path: 'mortgages',
        loadChildren: () =>
          import('./mortgages/mortgages.module').then((m) => m.MortgagesModule),
      },
      {
        path: 'moving',
        loadChildren: () =>
          import('./moving/moving.module').then((m) => m.MovingModule),
      },
      {
        path: 'blog',
        loadChildren: () =>
          import('./blog/blog.module').then((m) => m.BlogModule),
      },
      {
        path: 'favorites',
        loadChildren: () =>
          import('./favorites/favorites.module').then((m) => m.FavoritesModule),
      },
      {
        path: 'userfavorites',
        loadChildren: () =>
          import('./Account/user-favourite/user-favourite.module').then((m) => m.UserFavouriteModule),
      },
      {
        path: 'userdetails',
        loadChildren: () =>
          import('./Account/user-details/user-details.module').then((m) => m.UserDetailsModule),
      },
      {
        path: 'createadd',
        loadChildren: () =>
          import('./Account/create-advirtisement/create-advirtisement.module').then((m) => m.CreateAdvirtisementModule),
      },
      {
        path: 'userprofile', 

        loadChildren: () =>
          import('./Account/user-profile/user-profile.module').then((m) => m.UserProfileModule),
      },
      
      {
        path: 'pay',
        loadChildren: () =>
          import('./_partials/_paymentGateways/stripe/stripe.module').then((m) => m.StripeModule),
      },
      
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PageRoutingModule { }
