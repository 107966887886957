<!-- <nav class="navbar navbar-expand-sm navbar-light bg-light">
  <a class="navbar-brand" href="#">
    Navbar
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse sidenav" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#">
          Home
          <span class="sr-only">(current)</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">
          Link
        </a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          Dropdown
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">
            Action
          </a>
          <a class="dropdown-item" href="#">
            Another action
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">
            Something else here
          </a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link disabled" href="#">
          Disabled
        </a>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="btn btn-outline-success my-2 my-sm-0 ml-auto" type="submit">
        Search
      </button>
    </form>
  </div>
</nav> -->

<ul class="site-menu main-menu js-clone-nav ml-auto" *ngIf="mConfig && mConfig.items && scrWidth >920">
  <li *ngFor="let item of mConfig.items" [ngClass]="item.NodeType === nodeType.Root ? 'nav-item dropdown ' : ''">
    <a (click)="linkClick(item)"
      *ngIf="((item.id < 8 )|| (item.id == 8 && !user ))&& ((item.title === 'ADVERTISE' && userRoleValidate ===true) || (item.title != 'ADVERTISE'))">
      <span *ngIf="item.icon" class="pr-2" [ngClass]="item.iconClass"> </span>
      {{ item.title | translate }}
      <span *ngIf="item.badge" class="badge badge-primary badge-pill">
        {{ item.badgeText }}
      </span>
    </a>

    <a *ngIf="item.id == 9 && user" class="text-white topbar-item pt-0 pb-0 dropdown-toggle navi-link"
      id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span *ngIf="item.icon" class="pr-2" [ngClass]="item.iconClass"> </span>
      {{ item.title | translate }}
      <span *ngIf="item.badge" class="badge badge-primary badge-pill">
        {{ item.badgeText }} 
      </span>
    </a>
    <div class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="DropDownMenu">
      <div *ngFor="let ch of item.ChildNode">
      <a class="dropdown-item" *ngIf="(ch.title === 'MYPROPERTY' && userRoleValidate) || 
      (ch.title !== 'MYPROPERTY') "  (click)="linkClick(ch)">
        <div >
          <span *ngIf="ch.icon" class="pr-2" [ngClass]="ch.iconClass"> </span>
          {{ ch.title | translate }}
          <span *ngIf="ch.badge" class="badge badge-primary badge-pill">
            {{ ch.badgeText }}
          </span>
        </div>
      </a>
</div>
    </div>
  </li>
  <li class="language-holder ml-3">
    <app-language-selector></app-language-selector>
  </li>
</ul>


<ul class="site-menu main-menu js-clone-nav ml-auto" *ngIf="mobileConfig && mobileConfig.items && scrWidth <=920">
  <li *ngFor="let item of mobileConfig.items" [ngClass]="item.NodeType === nodeType.Root ? 'nav-item dropdown ' : ''">
    <a [routerLink]="item.page" (click)="sidebarclick(item)"
    *ngIf="((item.id < 8 )|| (item.id == 8 && !user ))&& ((item.title === 'ADVERTISE' && userRoleValidate ===true) || (item.title != 'ADVERTISE'))"
    [ngClass]="item.id == 8 ? 'log-wrap mb-4' : ''">
      <span *ngIf="item.icon" class="login-round mr-2"  [ngClass]="item.iconClass"> </span>
      {{ item.title | translate }}
      <span *ngIf="item.badge" class="badge badge-primary badge-pill">
        {{ item.badgeText }}
      </span>
    </a>

    <a *ngIf="item.id == 9 && user" class="text-white topbar-item pt-0 pb-0 dropdown-toggle navi-link"
      id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="item.id == 9 ? 'log-wrap mb-4' : ''">
      <span *ngIf="item.icon" class="login-round mr-2" [ngClass]="item.iconClass"> </span>
      {{ item.title | translate }}
      <span *ngIf="item.badge" class="badge badge-primary badge-pill">
        {{ item.badgeText }} 
      </span>
    </a>
    <div class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="DropDownMenu">
      <div *ngFor="let ch of item.ChildNode">
      <a class="dropdown-item" *ngIf="(ch.title === 'MYPROPERTY' && userRoleValidate) || 
      (ch.title !== 'MYPROPERTY') " [routerLink]="ch.page" (click)="sidebarclick(ch)">
        <div >
          <span *ngIf="ch.icon" class="pr-2" [ngClass]="ch.iconClass"> </span>
          {{ ch.title | translate }}
          <span *ngIf="ch.badge" class="badge badge-primary badge-pill">
            {{ ch.badgeText }}
          </span>
        </div>
      </a>
</div>
    </div>
  </li>
  <li class="language-holder">
    <app-language-selector></app-language-selector>
  </li>
</ul>
