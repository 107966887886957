<!-- <select (change)="setLanguageWithRefresh($event)">
  <ng-container *ngFor="let language of languages">
    <option   value={{language.ID}}>{{ language.Code}}</option>
  </ng-container>
 
</select> -->

<div class="dropdown" placement="bottom-right" >
  <div class="dropdown-toggle px-1 py-2" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
{{ language.Code }}</div>
  <div class="dropdown-menu">
          <a *ngFor="let language of languages" (click)="setLanguageWithRefresh(language)" class="dropdown-item">
            {{ language.Code}}
          </a>
  </div>
</div>



<!--
<div ngbDropdown class="dropdown" placement="bottom-right" [autoClose]="true">

  <div
    ngbDropdownToggle
    class="topbar-item"
    data-toggle="dropdown"
    data-offset="10px,0px"
  >
    <div
      class="btn btn-primary btn-dropdown btn-lg mr-1"
    >
      {{ language?.flag }}
    </div>
  </div>
   <div
   ngbDropdownMenu
   class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right"
 >
   <ul class="navi navi-hover py-4">
     <ng-container *ngFor="let language of languages">
       <li class="navi-item" [ngClass]="{ active: language.active }">
         <a
           href="javascript:;"
           (click)="setLanguageWithRefresh(language.lang)"
           [ngClass]="{ active: language.active }"
           class="navi-link"
         >
           <span class="navi-text">{{ language.name }}</span>
         </a>
       </li>
     </ng-container>
   </ul>
 </div>
</div> -->

