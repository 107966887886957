import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { LoaderService } from './core/services/GlobalServices/loader-service.service';
import { TranslateConfigService } from './core/services/GlobalServices/translate-config.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,AfterViewInit {
  title = 'ngImmotrade';
  show: boolean;

  constructor(private loaderService: LoaderService, private renderer: Renderer2) {

  }

  ngOnInit(){

  }
  ngAfterViewInit() {
    const div = document.createElement('div');
    //let scrHeight = window.innerHeight.toString();
    //let scrWidth = window.innerWidth;
    var height = document.getElementById('bodyWrap'). offsetHeight;
  div.className = 'loader-overlay';
  div.style.height=height +"px";
  div.innerHTML = `<div  class="spinner"></div>`;
    this.loaderService.httpProgress().subscribe((status: boolean) => {
      //this.show = status;
      if (status) {
        this.renderer.appendChild(document.body, div);
      } else {
        this.renderer.removeChild(document.body, div);
      }
    });
  }
}


// import { AfterViewInit, Component, Renderer2 } from '@angular/core';
// import { Loader, LoaderService, LoaderType } from './core/services/GlobalServices/loader-service.service';
// import { TranslateConfigService } from './core/services/GlobalServices/translate-config.service';

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.scss']
// })

// export class AppComponent implements AfterViewInit {
//   title = 'ngImmotrade';
//   show: boolean;
//   constructor(private loaderService: LoaderService, private renderer: Renderer2) { }

//   ngAfterViewInit() {
//     const div = document.createElement('div');

//     div.className = 'loader-overlay';

//     div.innerHTML = `<div  class="spinner"></div>`;
//     this.loaderService.httpProgress().subscribe((status: Loader) => {
//       //this.show = status;
//       if (status.Loader) {
//         if (status.loaderType == LoaderType.Default) {
//           this.renderer.appendChild(document.body, div);
//         }
//       } else {
//         this.renderer.removeChild(document.body, div);
//       }
//     });
//   }
// }
