import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})

export class VMPropertyNotification{

    ID: string;
    Name: string;
    Email: string;
    Phone: string;
    Message: string;
    PropertyID:number;
    IsAllow: boolean;
    IsRead:boolean;
    public constructor() {
        this.ID= "";
        this.Name ="";
        this.Email="";
        this.Phone="";
        this.Message= "";
        this.PropertyID=0;
        this.IsAllow=true;
        this.IsRead=false;

    }
}
