<div class="site-mobile-menu collapsible show site-navbar-target" style>
  <div class="site-mobile-menu-header">
    <div class="site-mobile-menu-close mt-3">
      <span class="icon-close2 js-menu-toggle"></span>
    </div>
  </div>
  <div class="site-mobile-menu-body">
    <app-header-menu class="site-nav-wrap"></app-header-menu></div>
</div>
<ng-container>
  <header #rla="routerLinkActive" routerLinkActive
    [ngClass]="
    isTransparent === true
        ? 'site-navbar site-navbar-target'
        : 'site-navbar site-navbar-inner site-navbar-target'
    "
    role="banner" id="mainheader">
    <div class="container">
      <div class="row align-items-center position-relative">
        <div class="col-3">
          <div class="site-logo">
            <a [routerLink]="['home']"><img src="/assets/images/auto-trade-logo.png" alt="Immotrade logo" /></a>
          </div>
        </div>

        <div class="col-9 text-right">
          <span class="d-lg-none collapsible">
            <span *ngIf="searchUrl.url.includes('/prop') && !searchUrl.url.includes('/propertydetails')" class="bg-white text-dark px-3 py-2 rounded-pill mr-5 d-sm-none" (click)="toggleDisplayDiv()"><span class="icon-search"></span> Search</span>
            <a href="#" class="text-white site-menu-toggle js-menu-toggle text-white" style="line-height: 20px;vertical-align: middle;">
              <span class="icon-menu mobile-bar text-white"> </span>
            </a>
          </span>
          <ng-container>
            <nav class="site-navigation text-right ml-auto d-none d-lg-block "
              role="navigation">
            <!-- <ul class="site-menu main-menu js-clone-nav ml-auto"></ul> -->
              <app-header-menu></app-header-menu>
            </nav>
          </ng-container>
        </div>
      </div>
      
    </div>
  </header>
</ng-container>
<!-- Header End -->
