<div class="CarouselContenar">
    <ngb-carousel
      #ThumbCarousel
      [activeId]="this.current"
      [showNavigationIndicators]="false"
      *ngIf="images"
      (slide)="onSlide($event)"
    >
      <ng-template
        [id]="'ngb-slide-' + i"
        ngbSlide
        *ngFor="let img of images; let i = index"
      >
        <div class="picsum-img-wrapper">
        <a href="#imagepopupModal" data-toggle="modal" data-target="#imagepopupModal">  <img [src]="img + fileobj" #ag (error)="ag.src ='/assets/images/no-image-600x350.jpg'" 
           alt="Random" class="img-fluid"/></a>
        </div>
        <!-- <div class="carousel-caption">
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </div> -->
      </ng-template>
    </ngb-carousel>
   
  </div>
  
  