import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { AuthModel } from 'src/app/pages/_VMClasses/Global/Global';
import { VMUserDetails } from 'src/app/pages/_VMClasses/propertylist/VMPropertyModel';
//import { environment } from 'src/environments/environment.prod';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../GlobalServices/global.service';
import { DynamicHeaderMenuService } from '../GlobalServices/header-menu.service';

const API_ROOT_URL = `${environment.apiUrl}`;
@Injectable({
  providedIn: 'root'
})
export class AuthHttpService {

  public DropDownMenu = new BehaviorSubject(true);
  public dropDownMenu: Observable<boolean> = this.DropDownMenu.asObservable();
  public currentUserSubject= new BehaviorSubject(new VMUserDetails);
  public currentUser$: Observable<VMUserDetails> = this.currentUserSubject.asObservable();
  //Observable<VMUserDetails> = this.SearchFlag.asObservable();
  isLoading$: Observable<boolean>;

  isLoadingSubject: BehaviorSubject<boolean>;
  private unsubscribe: Subscription[] = [];


  userRoleValidate: Observable<boolean>;
  UserRoleValidate:BehaviorSubject<boolean>;

  get currentUserValue(): VMUserDetails {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: VMUserDetails) {
    this.currentUserSubject.next(user);
  }

  constructor(private http: HttpClient,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private _global: GlobalService) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<VMUserDetails>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUser().subscribe();
    this.unsubscribe.push(subscr);

    this.UserRoleValidate = new BehaviorSubject<boolean>(false);
    //this.userRoleValidate=true;
    this.userRoleValidate= this.UserRoleValidate.asObservable();
    //this.UserRoleValidate.next(false);
  }
  
  externalLogin() {


    //return this.http.get<any>(`${API_ROOT_URL}/api/Account/ExternalLogin?provider=Google`);immo3162@gmail.com
      //return this.http.get<any>(`${API_ROOT_URL}/api/Account/ExternalLogin?provider=Google&response_type=token&client_id=self&redirect_uri=https%3A%2F%2Flocalhost%3A44365%2Fapi%2FAccount%2F%27http%3A%2F%2Flocalhost%3A4200%2Fhome%27`);

    this.document.location.href=`${API_ROOT_URL}/api/Account/ExternalLogin?provider=Google&response_type=token&client_id=self&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fhome&state=dQG1Km3CofLmzFI0qJYSVJ2E5Cg3zVckIBwdaWmz3Mk1`;
    //return this.http.get<any>(`${API_ROOT_URL}/api/Account/ExternalLogin?provider=Google`);
      //return this.http.get<any>(`${API_ROOT_URL}/api/Account/ExternalLogin?provider=Google&response_type=token&client_id=self&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fhome&state=dQG1Km3CofLmzFI0qJYSVJ2E5Cg3zVckIBwdaWmz3Mk1`);

  }
  // public methods
  login(email: string, password: string): Observable<VMUserDetails> {
    //this.logout();
    if (!email || !password) {
      return of(null);
    }
    const data = 'username=' + email + '&password=' + password + '&grant_type=password';
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });

    return this.http.post<AuthModel>(`${API_ROOT_URL}/token`, data, { headers: reqHeader }).pipe(
      map((auth: AuthModel) => {
        console.log(auth);
        const result = this._global.setAuthFromLocalStorage(auth, 'authLocalStorageToken');
        this.DropDownMenu.next(true);
        return result;
      }),
      switchMap(() => this.getUser()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );

    // return this.http.post<AuthModel>(API_USERS_URL, { email, password });
  }

  getUser(): Observable<VMUserDetails> {
    const auth = this._global.getAuthFromLocalStorage('authLocalStorageToken');
    if (!auth || !auth.access_token) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.getUserByToken().pipe(
      map((user: VMUserDetails) => {
        // LanguageManager
        // Agent
        // Admin
        // Subscriber
        // Owner
        if (user) {
          this.http.get<any>(`${API_ROOT_URL}/api/roles/GetRoleByUserEmail?email=${user.ContactMaster.Email}`).subscribe(r => {
            console.log('Role', r[0]);
            if (r) {
              if (r[0] === 'Agent' || r[0] === 'Owner') {
                this.UserRoleValidate.next(true);
              } else {
                this.UserRoleValidate.next(false);
              }
            } else {
              this.UserRoleValidate.next(false);
            }
          });
         console.log(this.userRoleValidate);
          const auth = this._global.setAuthFromLocalStorage(user, 'localStorageUserDetails');
          //this.currentUserSubject = new BehaviorSubject<VMUserDetails>(user);
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }

        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getUserByToken(): Observable<any> {
    const auth = this._global.getAuthFromLocalStorage('authLocalStorageToken');
    if (!auth || !auth.access_token) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.access_token}`,
    });


    return this.http.get<any>(`${API_ROOT_URL}/api/User/getUserByToken`, { headers: httpHeaders });
  }

  logout() {
    debugger
    this.UserRoleValidate.next(false);
    this.DropDownMenu.next(false);
    //this._MenuService.loadMenu();
    localStorage.removeItem('authLocalStorageToken');
    localStorage.removeItem('localStorageUserDetails');
    this.currentUserSubject.next(null);
    if(this.router.url != "/home" || this.router.url.includes("props")){
    this.router.navigate(['/account/login'], {
      queryParams: {},
    });
  }
  }






}
