import { Injectable } from '@angular/core';
import { AuthModel } from 'src/app/pages/_VMClasses/Global/Global';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_ROOT_URL = `${environment.apiUrl}`;
@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  // public TransparentHeader = new BehaviorSubject(true);
  // public transparentHeader: Observable<boolean> = this.TransparentHeader.asObservable();
  constructor(private http: HttpClient) { }
  public getSearchAttributes(): Observable<any> {
    return this.http.get("../../../../../assets/JSon/searchAttributes.json");
  }
public GetAttributeGroupDetailsByIds(data):Observable<any> {

  let prms = new HttpParams();
  data.forEach((id) =>{
    prms = prms.append(`Ids[]`, id.AttId);
  })

  return this.http.get(`${API_ROOT_URL}/api/GetAttributeGroupDetailsByIds`,{ params: prms });
}

  public getAuthFromLocalStorage(localStorageName: string): any {
    try {
      const authData = JSON.parse(
        localStorage.getItem(localStorageName)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public setAuthFromLocalStorage(auth: any, localStorageName: string): boolean {

    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (localStorageName == 'authLocalStorageToken') {
      if (auth && auth.access_token) {

        localStorage.setItem(localStorageName, JSON.stringify(auth));
        return true;
      }
    } else if (localStorageName == 'localStorageUserDetails') {
      if (auth && auth.ID) {

        localStorage.setItem(localStorageName, JSON.stringify(auth));
        return true;
      }
    }
    return false;
  }
}
