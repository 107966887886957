import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { DynamicHeaderMenuConfig } from 'src/app/pages/_layout/configs/header-menu.config';
const emptyMenuConfig = {
  items: []
};
@Injectable({
  providedIn: 'root'
})
export class DynamicHeaderMenuService {

  private menuConfigSubject = new BehaviorSubject<any>(emptyMenuConfig);
  menuConfig$: Observable<any>;
  constructor() {
    this.menuConfig$ = this.menuConfigSubject.asObservable();
    this.loadMenu();
  }

  // Here you able to load your menu from server/data-base/localeStorage
  // Default => from DynamicHeaderMenuConfig
  // tslint:disable-next-line: typedef
  public loadMenu() {
    this.setMenu(DynamicHeaderMenuConfig);
  }

  // tslint:disable-next-line: typedef
  private setMenu(menuConfig) {
    this.menuConfigSubject.next(menuConfig);
  }

  private getMenu(): any {
    return this.menuConfigSubject.value;
  }
}
