import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})

export class VMPropertyFavourite{

    PropertyId: number;
    UserId: string;
   
    public constructor() {
        this.PropertyId= 0;
        this.UserId ="";
    }
}
