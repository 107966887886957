import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./pages/Account/user-credentials/account.module').then((m) => m.AccountModule),
  }
  // {
  //   path: 'login',
  //   loadChildren: () =>
  //     import('./pages/Account/login/login.module').then((m) => m.LoginModule),
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
