import {
  Component,
  Input,
  OnDestroy,
  TemplateRef,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  KeyValueDiffer,
  KeyValueDiffers,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { VMPropertyMediaRelation } from '../../_VMClasses/propertylist/VMPropertyModel';
import { environment } from 'src/environments/environment';

export enum Direction {
  Next,
  Prev
}

export enum Animation {
  Fade = 'fade',
  Slide = 'slide'
}

export interface ActiveSlides {
  previous: number;
  current: number;
  next: number;
}
const IMG_ROOT_URL = `${environment.imgUrl}`;
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @Input()
  data: VMPropertyMediaRelation[];
  @Input()
  width:any;
  @Input()
  height:any;
  @Input()
  widththumb:any;
  @Input()
  heightthumb:any;
  @Input()
  thumbshow:boolean;
  @ViewChild('widgetsContent')
  widgetsContent!: ElementRef;
  @ViewChild('ThumbCarousel')
  ThumbCarousel!: NgbCarousel;
  activeSliderId: any;
  current: string = '';
  showNavigationArrows = true;
  showNavigationIndicators = true;
  pauseOnHover = true;
  fileobj: string;
  fileobjthumb: string;
  images = [];
  images1 = [];
  
  constructor(private cdr: ChangeDetectorRef) {

    
    this.current = 'ngb-slide-0';
    this.thumbshow=true;
  }
  ngOnInit(): void {
    this.thumbshow=true;
    this.fileobj = `?w=${this.width}&h=${this.height}&scale=both&bgcolor=0D1134&mode=crop`;
    this.fileobjthumb = `?w=${this.widththumb}&h=${this.heightthumb}&scale=both&bgcolor=0D1134&mode=crop`;
    this.data.forEach(img => {
      this.images.push(IMG_ROOT_URL + img.Medium.Path + img.Medium.Name);
     //this.images.push('assets/images/media/property/1029/images/' + img.Medium.Name);
      //
    })


  }

  onSlide(slideEvent: NgbSlideEvent) {

    this.current = slideEvent.current;

    this.current === "ngb-slide-0"? this.widgetsContent.nativeElement.scrollLeft = 0: this.widgetsContent.nativeElement.scrollLeft += 100;

    //this.widgetsContent.nativeElement.scrollLeft += 100;
    //this.cdr.detectChanges();
    console.log(this.current);
    console.log(this.widgetsContent.nativeElement.scrollLeft);
    console.log(this.widgetsContent.nativeElement.scrollRight);
    // console.log(this.activeSliderId);
    // console.log(slideEvent.source);
    // console.log(NgbSlideEventSource);
    // console.log(slideEvent);
    // console.log(NgbSlideEventSource.INDICATOR);
    // console.log(NgbSlideEventSource.ARROW_RIGHT);
  }
  startCarousel() {
    this.ThumbCarousel.cycle();
  }

  pauseCarousel() {
    this.ThumbCarousel.pause();
  }

  moveNext() {
    this.ThumbCarousel.next();
  }

  getPrev() {
    this.ThumbCarousel.prev();
  }

  goToSlide(slide: any) {
    this.ThumbCarousel.select("ngb-slide-" + slide);
    this.ThumbCarousel.select(slide);
  }

  scrollLeft(){
    this.widgetsContent.nativeElement.scrollLeft -= 100;
  }

  scrollRight(){
    // this.widgetsContent.nativeElement.scrollLeft += 100;

    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft += 100), behavior: 'smooth' });
  }
  onWheel(event: WheelEvent): void {
    event.preventDefault();
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft += event.deltaY), behavior: 'smooth' });
    //(<Element>event.target).parentElement.scrollLeft += event.deltaY;

  }
  hidethumb(){
    this.thumbshow=false;
  }
  showthumb(){
    this.thumbshow=true;
  }
}

