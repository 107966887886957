
export enum NodeType {
  Root, Leaf
}
export const DynamicHeaderMenuConfig = {
  items: [
   /* {
      id: '1',
      NodeType: NodeType.Leaf,
      ChildNode: [],
      title: 'PROPERTYLIST',
      root: true,
      badge: true,
      badgeText: 'FREE',
      icon: false,
      iconClass: '',
      alignment: 'left',
      page: '/prop',
      translate: 'MENU.HOME',
    },*/
    {
      id: '2',
      NodeType: NodeType.Leaf,
      ChildNode: [],
      title: 'RENT',
      root: true,
      badge: false,
      badgeText: '',
      icon: false,
      iconClass: '',
      alignment: 'left',
      page: '/home',
      translate: 'MENU.RENT',
      order:3,
    },
    {
      id: '3',
      NodeType: NodeType.Leaf,
      ChildNode: [],
      title: 'BUY',
      root: true,
      badge: false,
      badgeText: '',
      icon: false,
      iconClass: '',
      alignment: 'left',
      page: '/home',
      translate: 'MENU.BUY',
      order:4,
    },
    {
      id: '4',
      NodeType: NodeType.Leaf,
      ChildNode: [],
      title: 'ADVERTISE',
      root: true,
      badge: false,
      badgeText: '',
      icon: false,
      iconClass: '',
      alignment: 'left',
      page: '/createadd',
      translate: 'MENU.ADVERTISE',
      order:5,
    },
    /*{
      id: '5',
      NodeType: NodeType.Leaf,
      ChildNode: [],
      title: 'MOVING',
      root: true,
      badge: false,
      badgeText: '',
      icon: false,
      iconClass: '',
      alignment: 'left',
      page: '/moving',
      translate: 'MENU.MOVING',
    },
    {
      id: '6',
      NodeType: NodeType.Leaf,
      ChildNode: [],
      title: 'BLOG',
      root: true,
      badge: false,
      badgeText: '',
      icon: false,
      iconClass: '',
      alignment: 'left',
      page: '/blog',
      translate: 'MENU.BLOG',
    },*/

    {
      id: '8',
      NodeType: NodeType.Leaf,
      ChildNode: [],
      title: 'LOGIN',
      root: true,
      badge: false,
      badgeText: '',
      icon: true,
      iconClass: 'icon-user',
      alignment: 'left',
      page: '/account',
      translate: 'MENU.LOGIN',
      order:1,

    },
    {
      id: '9',
      NodeType: NodeType.Root,
      ChildNode: [{
                  id: '10',
                  title: 'FAVORITES',
                  NodeType: NodeType.Leaf,
                  ChildNode: [],
                  root: true,
                  badge: false,
                  badgeText: '',
                  icon: true,
                  iconClass: 'icon-heart',
                  alignment: 'left',
                  page: '/userfavorites',
                  translate: 'MENU.FAVORITES',
                },
                {
                  id: '11',
                  NodeType: NodeType.Leaf,
                  ChildNode: [],
                  title: 'MYPROPERTY',
                  root: true,
                  badge: false,
                  badgeText: '',
                  icon: true,
                  iconClass: 'icon-home',
                  alignment: 'left',
                  page: '/userdetails',
                  translate: 'MENU.LOGIN',
                },
                {
                  id: '12',
                  NodeType: NodeType.Leaf,
                  ChildNode: [],
                  title: 'MYPROFILE',
                  root: true,
                  badge: false,
                  badgeText: '',
                  icon: true,
                  iconClass: 'icon-user',
                  alignment: 'left',
                  page: '/userprofile',
                  translate: 'MENU.LOGIN',
                },
                {
                  id: '13',
                  title: 'Logout',
                  NodeType: NodeType.Leaf,
                  ChildNode: [],
                  root: true,
                  badge: false,
                  badgeText: '',
                  icon: true,
                  iconClass: 'icon-sign-out',
                  alignment: 'left',
                  page: '/account',
                  translate: 'MENU.LOGIN',
                }],
      title: 'ACCOUNT',
      root: true,
      badge: false,
      badgeText: '',
      icon: true,
      iconClass: 'icon-user',
      alignment: 'left',
      page: '',
      translate: 'MENU.ACCOUNT',
      order:2,
    }
  ]
};
