<div class="CarouselContenar">
  <ngb-carousel
    #ThumbCarousel
    [activeId]="this.current"
    [showNavigationIndicators]="false"
    *ngIf="images"
    (slide)="onSlide($event)"
  >
    <ng-template
      [id]="'ngb-slide-' + i"
      ngbSlide
      *ngFor="let img of images; let i = index"
    >
      <div class="picsum-img-wrapper">
      <a href="#imagepopupModal" data-toggle="modal" data-target="#imagepopupModal">  <img [src]="img + fileobj" #ag (error)="ag.src ='/assets/images/no-image-600x350.jpg'" 
         alt="Random" class="img-fluid"/></a>
      </div>
      <!-- <div class="carousel-caption">
        <h3>First slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </div> -->
    </ng-template>
  </ngb-carousel>
  <div class="arrow pt-1">
      <i
        class="icon-arrow-left pointer hover-display arrLeft"
        (click)="scrollLeft()"
        *ngIf="images.length > 3"
        ></i>
    <div #widgetsContent class="scrolling-wrapper" (wheel)="onWheel($event)" *ngIf="thumbshow">
      <div
        class="card m-0 p-0 carousel-thumbnail__item list-inline-item"
        *ngFor="let img of images; let i = index"
        (click)="goToSlide(i)"
      >
        <div class="p-1">
          <a class="active">
            <img
              class="carousel-thumbnail__img"
              [ngClass]="current === 'ngb-slide-' + i ? 'selected' : ''"
              [src]="img + fileobjthumb" #am (error)="am.src ='/assets/images/no-image-600x350.jpg'" 
            />
          </a>
        </div>
      </div>
      <i class="icon-arrow-right pointer hover-display arrRight"
      (click)="scrollRight()"
      *ngIf="images.length > 3"></i>
    </div>
  </div>
</div>

