<form #contactagent="ngForm" class="form" autocomplete="off" (ngSubmit)="submit()" >
  
  <div class="row">
  <div class="col-12 col-sm-6 col-lg-12 form-group">
   
    <input type="text" [(ngModel)]="_VMNotification.Name"
     name="name" class="form-control"
     placeholder="{{'NAME' | translate}}"
     #name="ngModel" minlength="2" required="true"/>
     <div *ngIf="isControlHasError(name, 'required')" class="alert alert-danger">
     <!-- <div *ngIf="BrochureName.errors.required"> -->
     {{'FIRSTNAMEISREQUIRED' | translate}}
    </div>
    <div *ngIf="name.errors?.minlength" class="alert alert-danger">
    <strong>Invalid Name</strong></div>
  </div>
  <div class="col-12 col-sm-6 col-lg-12 form-group">
    <input 
    type="text" 
    [(ngModel)]="_VMNotification.Phone" 
    name="phone"
    #phone="ngModel"
    maxlength="15"
    pattern='^[0-9]{4,}$'
    placeholder="{{'PHONENUMBER' | translate}}" 
    class="form-control"
    required="true"/>
    <div *ngIf="phone.errors?.pattern" class="alert alert-danger">
      <strong>Invalid phone number</strong>
</div>
    <!-- <div *ngIf="
    isControlHasError(email, 'required')
  " class="alert alert-danger">
 
    <strong>* {{'EMAILISREQUIRED' | translate}}</strong>
</div> -->
<!-- <div *ngIf="isControlHasError('email', 'email')" class="alert alert-danger">
  <strong>* {{'EMAILISNOTVALID' | translate}}</strong>
</div> -->
    <!-- <span *ngIf="isControlHasError('Email', 'email')">
      <strong>* Email is not valid</strong>
    </span> -->
   
  </div>
  <div class="col-12 col-sm-12 col-lg-12 form-group">
    <input 
    type="email" 
    [(ngModel)]="_VMNotification.Email" 
    name="email"
    pattern='^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
    placeholder="{{'EMAIL' | translate}}" 
    class="form-control" 
    #email="ngModel"
    required="true"/>
    <div *ngIf="isControlHasError(email, 'required')" class="alert alert-danger">
  <!-- <div *ngIf="BrochureName.errors.required"> -->
    <strong>* {{'EMAILISREQUIRED' | translate}}</strong>
    
</div>

<div  *ngIf="email.errors?.pattern" class="alert alert-danger">
      <strong>Invalid Email</strong>
</div>
<!-- <div *ngIf="isControlHasError('email', 'email')" class="alert alert-danger">
  <strong>* {{'EMAILISNOTVALID' | translate}}</strong>
</div> -->
    <!-- <span *ngIf="isControlHasError('Email', 'email')">
      <strong>* Email is not valid</strong>
    </span> -->
   
  </div>
  <div class="col-12 col-sm-12 col-lg-12 form-group">
    <textarea class="form-control" 
    [(ngModel)]="_VMNotification.Message" minlength="3"
    name="message"rows="3" placeholder="{{'MESSAGE' | translate}}">
  </textarea>
  </div> 
  <div class="col-12 col-sm-12 col-lg-12 form-group agents-checkbox">
    <div class="custom-control custom-checkbox">
     <input id="customCheck1" type="checkbox"
        [(ngModel)]="_VMNotification.IsAllow" name="IsAllow"
        class="custom-control-input checkbox-muted" checked />
      <label for="customCheck1"
        class="custom-control-label custom-control-label agents-check-label">
        Allow other agents to contact you
      </label>
    </div>
  </div>
  <div class="col-12 col-sm-12 col-lg-12 form-group">
    <div class="btn-group-justified">
      <button type="submit" class="btn btn-block btn-dark">{{'SUBMIT' | translate}}</button>
    </div>
  </div>
</div>
</form>
<!-- <div class="col-md-12 text-center" *ngIf="isControlHasError('id', 'required')">
  You have not logged in, Please<a [routerLink]="['/account/login']" class="class="btn btn-block btn-dark"">
    Login</a></div> -->