import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/core/services/GlobalServices/layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  isfooterShow : boolean;
  
  constructor(private layoutService: LayoutService) { 
    this.isfooterShow=true;
                this.layoutService.isFooterShow.subscribe(q=>{
                  this.isfooterShow=q;
                })
  }

  ngOnInit(): void {
    this.layoutService.isFooterShow.subscribe((q: boolean) => {
      this.isfooterShow = q;
    });
    //this.c
  }

}
