import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PropertylistAdminService } from 'src/app/core/services/PageServices/propertylist-admin.service';
import { PropertylistService } from 'src/app/core/services/PageServices/propertylist.service';
import { environment } from 'src/environments/environment';
import { VMPropertyFavourite } from '../_VMClasses/propertylist/VMPropertyFavourite';
import { VMProperty } from '../_VMClasses/propertylist/VMPropertyModel';
import { Location } from '@angular/common';
import { get } from 'scriptjs';
import { LayoutService } from 'src/app/core/services/GlobalServices/layout.service';
import { GlobalService } from 'src/app/core/services/GlobalServices/global.service';
import { PopupCarouselComponent } from '../_partials/popup-carousel/popup-carousel.component';

const IMG_ROOT_URL = `${environment.imgUrl}`;
interface addthis {
  layers: Refresh;
  init: Function;
  toolbox: Function;
}
interface Refresh {
  refresh: Function;
}
declare var addthis: addthis;
@Component({
  selector: 'app-propertydetails',
  templateUrl: './propertydetails.component.html',
  styleUrls: ['./propertydetails.component.scss'],
})
export class PropertydetailsComponent implements OnInit {
  //@Input() public data;
  images: Array<string>;
   BASE_PATH: string;
   fileobj: string;
   furnishingType:string = "no"; 
  tempPath="../../../../../assets/images/img_11.jpg"; 
  AvailabiltyStatus:string;
  _Propertydata: VMProperty;
  contact : boolean = true;
  propId: any;
  fav:string;
  _PropertyFav: VMPropertyFavourite;

  isInit:boolean = false;
  constructor(public proplistAdminService: PropertylistAdminService,private route: ActivatedRoute,
              config: NgbCarouselConfig,private propService: PropertylistService, private _global: GlobalService,
              private toastr: ToastrService,private router: Router,private _location: Location,
              private renderer: Renderer2,private layoutService: LayoutService) 
  { 
    //this.reloadCurrentRoute();
    this._Propertydata = new VMProperty();
    this._PropertyFav = new VMPropertyFavourite();
    this.route.queryParams.subscribe(params => {
        console.log('PARAM',params)
        this.propId = params.propId;
        localStorage.setItem('propId', this.propId);
        this.fav=params.favcount;
      }, err => {
        console.log(err)
      }
      );
      this.layoutService.IsTransparent.next(false);
    }
  ngOnInit(): void {
  this.BASE_PATH = IMG_ROOT_URL;  //http://fileserver.infrastep.com/';
  //ra-619e09c74d416063// old key//
  this.addJsToElement('https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-61c381910778cd1c').onload = (teste) => {
    console.log(teste);
}
  
    if(this.propId){
    this.proplistAdminService.getPropertydetailsbypropid(this.propId).subscribe(q => {
      if (q) {
        let user = this._global.getAuthFromLocalStorage('localStorageUserDetails');
        var UserID;
        if(user !=null){
           UserID = user.ID;
          }else{
            UserID='';
          }
        this._Propertydata = q;
        if(this._Propertydata.PropertyFavourites.length>0){
          this._Propertydata.PropertyFavourites.forEach(element => {
            if(UserID==element.UserId){
              this._Propertydata.FavCount="1";
            }
            else if( UserID=='') {
              this._Propertydata.FavCount="0";
            }
          });
        }else{
          this._Propertydata.FavCount="0";
        }
        
        console.log('propertydata--',this._Propertydata)
        
        //this._Propertydata.FavCount=this.fav;
      }
    });
  }
  window.scrollTo(0, 0);
  if (typeof document !== 'undefined') {
    if(typeof addthis !== 'undefined' && addthis.layers && addthis.layers.refresh) {
      console.log("Add This: Call Refresh")
      addthis.layers.refresh();
    }
  }
  }
  // ngAfterViewInit(){
  // //  var bb= document.getElementById('addthis').layers.refresh()
  // //  window.bb.layers.refresh();
  
  // //window.addthis.layers.refresh();
  // this.addJsToElement('https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-619e09c74d416063')
  // .onload = (teste) => {
  //   console.log(teste);
  //  }
  // }
  // reloadCurrentRoute() {
  //   let currentUrl = this.router.url;
  //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  //     this.router.navigate([currentUrl]);
  //   });
  // }
  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(document.body, script);
    return script;
  }

  sharebtns(){
    this.isInit = true;
  }
  postfavouriteProperty(propId) {

    var ls = JSON.parse(localStorage.getItem('localStorageUserDetails'));
  
    if (ls != null) {
      this._Propertydata.UserID = ls.ID;
      this._Propertydata.ID = propId;

      this._PropertyFav.UserId = ls.ID;
      this._PropertyFav.PropertyId = propId;

      this.propService.PostPropertyFavourite(this._PropertyFav).subscribe((i: string) => {
        console.log(i);
        if (i != "") {
          if (i == "Wishlisted") {
          this.toastr.success(i);
          this._Propertydata.FavCount = "1"
          }
          else{
            this.toastr.success(i);
            this._Propertydata.FavCount = "0"
          }
        }
        else {
          this.toastr.error('Something Wrong!!', 'Sorry!');
        }
      });
  }
  else {
    this.router.navigate(['/account']);
  }
  }
  backClicked() {
    this._location.back();
  }
}
