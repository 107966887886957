<!-- <div class="container backcont mt-4">
  <div class="row">
    <div class="col-md-12 mb-3 text-left px-sm-0 px-lg-0">
      <a type="button" class="btn btn-dark" (click)="backClicked()">Back</a>
    </div>
  </div> 
</div> -->
<div class="container propwrap mb-5 mt-3 d-none d-sm-none d-lg-block">
 
  <div class="row">
    <div class="col-12 col-sm-12 col-lg-8 mb-3">

      <div class="prop-info-wrap">
        <h1 class="property-title mb-2">
          {{ _Propertydata.Currency.Symbol}} {{ _Propertydata.PriceFrom }}
          <span *ngIf="_Propertydata.IsRent">
            /
            <span *ngIf="_Propertydata.RentPer === 1"> month </span>
            <span *ngIf="_Propertydata.RentPer === 2"> week </span>
            <span *ngIf="_Propertydata.RentPer === 3"> day </span>
          </span>
        </h1>
        <div class="property-address mb-3">
          <span class="icon-room"></span>{{ _Propertydata.AddressMaster.Address1 }}
        </div>
        <ul class="property-info list-unstyled d-flex mb-1 mt-2">
          <li class="badge badge-secondary px-2 mr-2">
            <b>{{ _Propertydata.Bedroom }}</b> Bed
          </li>
          <!-- <li *ngIf="this.furnishingType != 'no'">
            {{ this.furnishingType }}
          </li> -->

          <li class="badge badge-secondary px-2 mr-2">
            <b>{{ _Propertydata.LotSize }}</b> {{ _Propertydata.SizeUnit }}<sup>2</sup>
          </li>
        </ul>
        <div class="row">
          <div class="col-12 col-sm-11 col-lg-11">
            <div class="color-dark d-inline-block w-100">
              <img src='../../../assets/images/share.png'/>&nbsp;&nbsp;
              <span class="d-inline-block font-weight-bold">{{ "SHARE" | translate }}:</span> 
              <span class="addthis_inline_share_toolbox"></span>
            </div>
          </div>
          <div class="col-12 col-sm-1 col-lg-1 text-right mt-4">
            <a *ngIf="contact" (click)="postfavouriteProperty(_Propertydata.ID)" class="whishlist"
            [ngClass]="{ 'text-grey-wish': _Propertydata.FavCount == '0','text-dark': _Propertydata.FavCount == '1' }"><span class="icon-favorite"></span>
            </a>
          </div>
        </div>

      </div>
    </div>

    <div class="col-12 col-sm-12 col-lg-4 mb-4">
      <!-- <div *ngIf="_Propertydata.IsRent">
        <span class="property-title float-right">{{ "RENT" | translate }} :  <b>{{ _Propertydata.Currency.Symbol }}{{ _Propertydata.PriceFrom }}</b></span>
       
      </div>
      <div *ngIf="!_Propertydata.IsRent">
        <span class="property-title float-right">{{ "BUY" | translate }} : <b>{{ _Propertydata.Currency.Symbol }}{{ _Propertydata.PriceFrom }}</b></span>
        
      </div> -->
     
       <div class="property-avaliability">
    <!-- <p class="mb-1">
      <b>{{ "AVALIABILITY" | translate }}</b>
    </p> -->
  
       <h5 class="mt-2 text-lg-right" *ngIf="_Propertydata.AvailableFrom !=''">
        {{ "AVAILABLEFROM" | translate }}:&nbsp;&nbsp;  <b> <small>{{ _Propertydata.AvailableFrom }}</small> </b>
       </h5>
  </div>
    </div>

<div class="col-12 col-sm-12 col-lg-8 mb-3">
  <div class="property-gallery mb-3">
    <ng-container *ngIf="_Propertydata.PropertyMediaRelations.length>0 ; else elseimageBlock">
      <app-carousel id="1" [width]="1400" [height]="1000" [widththumb]="140" [heightthumb]="100"
        [data]="_Propertydata.PropertyMediaRelations"></app-carousel>
    </ng-container>
    <ng-template #elseimageBlock>
      <div class="property-description mb-2">
        <img class="noimg img-fluid w-100" src='../../../assets/images/NoIMG.jpg'/>&nbsp;&nbsp;
      </div>
    </ng-template>
    <!-- <div *ngIf="contact" class="favorite-icon p-3">
      <a (click)="postfavouriteProperty(_Propertydata.ID)"
        [ngClass]="{ 'text-white': _Propertydata.FavCount === 0 }"><span class="icon-favorite"></span>
      </a>
    </div>
    <div class="share-icon p-3">
      <a href="#" class="text-white"><span class="icon-share-alt"></span>
        {{ "SHARE" | translate }}</a>
    </div> -->

  </div>
  <div class="property-description mt-3">
    <h4 class="mb-1">
      <b>{{ "DESCRIPTION" | translate }}</b>
    </h4>
    <p>
      {{ _Propertydata.Description }}
    </p>
  </div>
  <div class="property-more-info mt-3">
    <h4 class="mb-1">
      <b>{{ "MOREINFORMATION" | translate }}</b>
    </h4>
    <ul class="list-item">
      <li>
        <span>{{ "YEARBUILD" | translate }}</span>
        <b>{{ _Propertydata.YearBuild }}</b>
      </li>
      <li>
        <span>{{ "NOOFROOMS" | translate }}</span>
        <b>{{ _Propertydata.NumRoom }}</b>
      </li>
      <li>
        <span>{{ "NOOFFLOORS" | translate }}</span>
        <b>{{ _Propertydata.FloorNumber }}</b>
      </li>
      <li>
        <span>{{ "SUFACELIVING" | translate }}</span>
        <b>{{ _Propertydata.FloorSpace }} {{ _Propertydata.SizeUnit }}<sup>2</sup></b>
      </li>
    </ul>
  </div>
  
  <div class="property-features mt-3">
    <h4 class="mb-1">
      <b>{{ "FEATURES" | translate }}</b>
    </h4>
    <ul class="list-item features-list">
      <li *ngFor="let pa of _Propertydata.PropertyAttributeRelations">
        <ng-template [ngIf]="pa.Value">{{pa.AttributeValue.ValueName}}</ng-template>
      </li>
    </ul>
  </div>

</div>

<div class="col-12 col-sm-12 col-lg-4 mb-3">
  <div class="card bg-lightgray property-cost mb-5">
    <div class="card-body p-3">
      <div class="border-bottom mb-3">
        <h5 class="text-dark font-weight-bold">
          <i class="fa fa-info-circle mr-2"></i>Agent Info</h5>
      </div>
      <ul class="list-item mb-0">
        <li class="property-agent border-bottom bg-white p-2">
          <!-- *ngIf="_Propertydata.IsRent" -->
          <div class="property-agent-image">
            <img  src="{{ _Propertydata.UserDetail.MediaID != null 
                ? BASE_PATH + 
                _Propertydata.UserDetail.Medium.Path + 
                _Propertydata.UserDetail.Medium.Name : 
                '../../../assets/images/blank.png' 
              }}"alt="..." /> 
          </div>
          <div class="property-agent-name-1">
            <p><b>{{ _Propertydata.UserDetail.FirstName }}.
                {{ _Propertydata.UserDetail.LastName}}</b>
            </p>
            <p><small>Agent</small></p>
          </div>
        </li>
      </ul>
    </div>
</div>
  
<div class="agent-contact-form">
  <div class="form-group">
    <h4 class="font-size-16 text-white mt-2">
      {{ "CONTACTSELLER" | translate }}
    </h4>
  </div>
  <app-contactagent [data]="_Propertydata"></app-contactagent>
  <div class="form-group">
    <p class="text-white denomination-text" style="font-size: 16px;line-height: 20px; text-align:justify;">
      <small>By pressing Contact Agent, you agree that immotrade365 and its affiliates, and real estate
        professionals may call/text you about your inquiry, which may involve use of automated means and
        prerecorded/artificial voices. You don't need to consent as a condition of buying any property,
        goods or services. Message/data rates may apply. You also agree to our Terms of Use. immotrade365
        does not endorse any real estate professionals.
      </small>
    </p>
  </div>
</div>


</div>

</div>

</div>

<!-- For Mobile -->
<div class="container propwrap mb-5  d-block d-sm-block d-lg-none">
 
  <div class="row">
    <div class="col-12 col-sm-12 col-lg-8">

      <div class="prop-info-wrap">
        <div class="row">
          
          <div class="col-10 col-sm-11 col-lg-11 pr-0">
            <div class="row">
              <div class="col-4 pr-0">
                <div class="color-dark d-inline-block w-100">
                  <img src='../../../assets/images/share.png'/>&nbsp;
                  <span class="d-inline-block font-weight-bold">{{ "SHARE" | translate }}:</span> 
                </div>
              </div>
              <div class="col-8 pl-0">
                <span class="addthis_inline_share_toolbox"></span>
              </div>
            </div>
           
          </div>
          <div class="col-2 col-sm-1 col-lg-1 text-right">
            <a *ngIf="contact" (click)="postfavouriteProperty(_Propertydata.ID)" class="whishlist"
            [ngClass]="{ 'text-grey-wish': _Propertydata.FavCount == '0','text-dark': _Propertydata.FavCount == '1' }"><span class="icon-favorite"></span>
            </a>
          </div>
        </div>
        
        <div class="property-gallery mb-3">
          <ng-container *ngIf="_Propertydata.PropertyMediaRelations.length>0;else elseimageBlock">
            <app-carousel id="1" [width]="1400" [height]="1000" [widththumb]="140" [heightthumb]="100"
              [data]="_Propertydata.PropertyMediaRelations"></app-carousel>
          </ng-container>
          <ng-template #elseimageBlock>
            <div class="property-description mb-2">
              <img class="noimg img-fluid w-100" src='../../../assets/images/NoIMG.jpg'/>&nbsp;&nbsp;
            </div>
          </ng-template>
          <!-- <div *ngIf="contact" class="favorite-icon p-3">
            <a (click)="postfavouriteProperty(_Propertydata.ID)"
              [ngClass]="{ 'text-white': _Propertydata.FavCount === 0 }"><span class="icon-favorite"></span>
            </a>
          </div>
          <div class="share-icon p-3">
            <a href="#" class="text-white"><span class="icon-share-alt"></span>
              {{ "SHARE" | translate }}</a>
          </div> -->
      
        </div>
        <div class="row ">
          <div class="col-7">
            <h1 class="property-title mb-2">
              {{ _Propertydata.Currency.Symbol}} {{ _Propertydata.PriceFrom }}
              <span *ngIf="_Propertydata.IsRent">
                /
                <span *ngIf="_Propertydata.RentPer === 1"> month </span>
                <span *ngIf="_Propertydata.RentPer === 2"> week </span>
                <span *ngIf="_Propertydata.RentPer === 3"> day </span>
              </span>
            </h1>
          </div>
          <div class="col-5">
            <ul class="property-info list-unstyled d-flex mb-1 mt-2">
              <li class="badge badge-secondary px-2 mr-2">
                <b>{{ _Propertydata.Bedroom }}</b> Bed
              </li>
              <!-- <li *ngIf="this.furnishingType != 'no'">
                {{ this.furnishingType }}
              </li> -->
    
              <li class="badge badge-secondary px-2 mr-2">
                <b>{{ _Propertydata.LotSize }}</b> {{ _Propertydata.SizeUnit }}<sup>2</sup>
              </li>
            </ul>
          </div>
        </div>
        
        <div class="property-address">
          <span class="icon-room"></span>{{ _Propertydata.AddressMaster.Address1 }}
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-lg-4">
      <!-- <div *ngIf="_Propertydata.IsRent">
        <span class="property-title float-right">{{ "RENT" | translate }} :  <b>{{ _Propertydata.Currency.Symbol }}{{ _Propertydata.PriceFrom }}</b></span>
       
      </div>
      <div *ngIf="!_Propertydata.IsRent">
        <span class="property-title float-right">{{ "BUY" | translate }} : <b>{{ _Propertydata.Currency.Symbol }}{{ _Propertydata.PriceFrom }}</b></span>
        
      </div> -->
     
       <div class="property-avaliability">
    <!-- <p class="mb-1">
      <b>{{ "AVALIABILITY" | translate }}</b>
    </p> -->
  
       <h5 class="mt-2 text-lg-right" *ngIf="_Propertydata.AvailableFrom !=''">
        {{ "AVAILABLEFROM" | translate }}:&nbsp;&nbsp;  <b> <small>{{ _Propertydata.AvailableFrom }}</small> </b>
       </h5>
  </div>
    </div>

<div class="col-12 col-sm-12 col-lg-8 mb-3">
  
  <div class="property-description mt-3">
    <h4 class="mb-1">
      <b>{{ "DESCRIPTION" | translate }}</b>
    </h4>
    <p>
      {{ _Propertydata.Description }}
    </p>
  </div>
  <div class="property-more-info mt-3">
    <h4 class="mb-1">
      <b>{{ "MOREINFORMATION" | translate }}</b>
    </h4>
    <ul class="list-item">
      <li>
        <span>{{ "YEARBUILD" | translate }}</span>
        <b>{{ _Propertydata.YearBuild }}</b>
      </li>
      <li>
        <span>{{ "NOOFROOMS" | translate }}</span>
        <b>{{ _Propertydata.NumRoom }}</b>
      </li>
      <li>
        <span>{{ "NOOFFLOORS" | translate }}</span>
        <b>{{ _Propertydata.FloorNumber }}</b>
      </li>
      <li>
        <span>{{ "SUFACELIVING" | translate }}</span>
        <b>{{ _Propertydata.FloorSpace }} {{ _Propertydata.SizeUnit }}<sup>2</sup></b>
      </li>
    </ul>
  </div>
  
  <div class="property-features mt-3">
    <h4 class="mb-1">
      <b>{{ "FEATURES" | translate }}</b>
    </h4>
    <ul class="list-item features-list">
      <li *ngFor="let pa of _Propertydata.PropertyAttributeRelations">
        <ng-template [ngIf]="pa.Value">{{pa.AttributeValue.ValueName}}</ng-template>
      </li>
    </ul>
  </div>

</div>

<div class="col-12 col-sm-12 col-lg-4 mb-3">
  <div class="card bg-lightgray property-cost mb-5">
    <div class="card-body p-3">
      <div class="border-bottom mb-3">
        <h5 class="text-dark font-weight-bold">
          <i class="fa fa-info-circle mr-2"></i>Agent Info</h5>
      </div>
      <ul class="list-item mb-0">
        <li class="property-agent border-bottom bg-white p-2">
          <!-- *ngIf="_Propertydata.IsRent" -->
          <div class="property-agent-image">
            <img  src="{{ _Propertydata.UserDetail.MediaID != null 
                ? BASE_PATH + 
                _Propertydata.UserDetail.Medium.Path + 
                _Propertydata.UserDetail.Medium.Name : 
                '../../../assets/images/blank.png' 
              }}"alt="..." /> 
          </div>
          <div class="property-agent-name-1">
            <p><b>{{ _Propertydata.UserDetail.FirstName }}.
                {{ _Propertydata.UserDetail.LastName}}</b>
            </p>
            <p><small>Agent</small></p>
          </div>
        </li>
      </ul>
    </div>
</div>
  
<div class="agent-contact-form">
  <div class="form-group">
    <h4 class="font-size-16 text-white mt-2">
      {{ "CONTACTSELLER" | translate }}
    </h4>
  </div>
  <app-contactagent [data]="_Propertydata"></app-contactagent>
  <div class="form-group">
    <p class="text-white denomination-text" style="font-size: 16px;line-height: 20px; text-align:justify;">
      <small>By pressing Contact Agent, you agree that immotrade365 and its affiliates, and real estate
        professionals may call/text you about your inquiry, which may involve use of automated means and
        prerecorded/artificial voices. You don't need to consent as a condition of buying any property,
        goods or services. Message/data rates may apply. You also agree to our Terms of Use. immotrade365
        does not endorse any real estate professionals.
      </small>
    </p>
  </div>
</div>


</div>

</div>
</div>

<div class="modal fade modal-bg" id="imagepopupModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="_Propertydata.PropertyMediaRelations.length>0">
          <app-popup-carousel id="1" [width]="1400" [height]="1000" [widththumb]="140" [heightthumb]="100" [thumbshow]="false"
            [data]="_Propertydata.PropertyMediaRelations" ></app-popup-carousel>
        </ng-container>
      </div>
    </div>
  </div>
</div>

