import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { AnyMxRecord } from 'dns';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserPropertyList, VMProperty } from 'src/app/pages/_VMClasses/propertylist/VMPropertyModel';
//import { environment } from 'src/environments/environment.prod';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../GlobalServices/global.service';
import { VMSearch } from 'src/app/pages/_VMClasses/search/searchObject';
const API_ROOT_URL = `${environment.apiUrl}`;
@Injectable({
    providedIn: 'root'
})
export class PropertylistService {
    _searchFlag: VMSearch = new VMSearch();
    private SearchFlag = new BehaviorSubject(this._searchFlag);
    searchFlag: Observable<VMSearch> = this.SearchFlag.asObservable();

    public SearchFocused = new BehaviorSubject(false);
    public searchFocused: Observable<boolean> = this.SearchFocused.asObservable();
    public ListDataSubject = new BehaviorSubject({});
    public ListFlag: Observable<any> = this.ListDataSubject.asObservable();

    constructor(private http: HttpClient, private _global: GlobalService) {
        //this._searchFlag= new VMSearch();
    }
    getFeaturedProperty(){
      return this.http.get<VMProperty[]>(`${API_ROOT_URL}/api/Property/getFeaturedProperty`);
    }
    setSearchFlag(search: VMSearch){
      this.SearchFlag.next(search);
    }

    GetAllPropertyList(): Observable<VMProperty[]> {

        return this.http.get<VMProperty[]>(`${API_ROOT_URL}/api/Property`);
    }

    GetBoundedPropertyList(poly: string): Observable<VMProperty[]> {
        let prms = new HttpParams();
        prms = prms.append('polygon', poly);
        //prms = prms.append('type', filterType);
        return this.http.get<VMProperty[]>(
            `${API_ROOT_URL}/api/Property/propertybymap`,
            { params: prms }
        );
        // return this.http.post<VMProperty[]>( `${API_ROOT_URL}api/Property/propertybymap`, poly );
    }

    GetAllPropertyById(pageno:any): Observable<UserPropertyList> {
        let user = this._global.getAuthFromLocalStorage('localStorageUserDetails');
        let userId = user.ID;
        let prms = new HttpParams();
        prms = prms.append('userid', userId);
        prms = prms.append('pagenum',pageno);
        console.log(userId);
        return this.http.get<UserPropertyList>(`${API_ROOT_URL}/api/Property/propertydetailsbyuser`, { params: prms });
    }

    postContactAgent(postData: any) {
      
        return this.http.post<number>(`${API_ROOT_URL}/api/Property/postpropertynotification`, postData);

    }
    postUserRegistration(postData: any) {

        return this.http.post<string>(`${API_ROOT_URL}/api/Account/UserRegister`, postData);
    }

    

    GetBoundedPropertyListBySearchB(poly: string, Isrent: any, mnBedroom: any,mxBedroom: any, SPrice: any, EPrice: any,mnSize: any,mxSize: any,Floor: any, attr: string,UserID: string,pagesize : any,pagenumber:any): Observable<any> {
      let user = this._global.getAuthFromLocalStorage('localStorageUserDetails');
      if(user !=null){
      UserID = user.ID;
      }


      let prms = new HttpParams();
      prms = prms.append('polygon', poly);
      prms = prms.append('rent', Isrent);
      prms = prms.append('mnbedroom', mnBedroom);
      prms = prms.append('mxbedroom', mxBedroom);
      prms = prms.append('sprice', SPrice);
      prms = prms.append('eprice', EPrice);
      prms = prms.append('mnsize', mnSize);
      prms = prms.append('mxsize', mxSize);
      prms = prms.append('floor', Floor);
      prms = prms.append('attr', attr);
      prms = prms.append('UserID', UserID);
      prms = prms.append('pageno', pagenumber);
      prms = prms.append('pagesize', pagesize);
      // return this.http.get<VMProperty[]>(
      //     `${API_ROOT_URL}/api/Property/propertybymapwithParam`,
      //     { params: prms }
      // );
      return this.http.get<VMProperty[]>(
        `${API_ROOT_URL}/api/Property/Get_PropertiesBySearch`,
        { params: prms }
    );
      // return this.http.post<VMProperty[]>( `${API_ROOT_URL}api/Property/propertybymap`, poly );
  }

   GetBoundedPropertyListBySearch(poly: string, Isrent: any, mnBedroom: any,mxBedroom: any, SPrice: any, EPrice: any,mnSize: any,mxSize: any,Floor: any, attr: string,UserID: string,pagesize : any,pagenumber:any) {
    let user = this._global.getAuthFromLocalStorage('localStorageUserDetails');
    if(user !=null){
    UserID = user.ID;
    }


    let prms = new HttpParams();
    prms = prms.append('polygon', poly);
    prms = prms.append('rent', Isrent);
    prms = prms.append('mnbedroom', mnBedroom);
    prms = prms.append('mxbedroom', mxBedroom);
    prms = prms.append('sprice', SPrice);
    prms = prms.append('eprice', EPrice);
    prms = prms.append('mnsize', mnSize);
    prms = prms.append('mxsize', mxSize);
    prms = prms.append('floor', Floor);
    prms = prms.append('attr', attr);
    prms = prms.append('UserID', UserID);
    prms = prms.append('pageno', pagenumber);
    prms = prms.append('pagesize', pagesize);
    // return this.http.get<VMProperty[]>(
    //     `${API_ROOT_URL}/api/Property/propertybymapwithParam`,
    //     { params: prms }
    // );
    return  this.http.get<any>(
      `${API_ROOT_URL}/api/Property/Get_PropertiesBySearchlist`,
      { params: prms }
  );
    // return this.http.post<VMProperty[]>( `${API_ROOT_URL}api/Property/propertybymap`, poly );
}

 async GetBoundedPropertyListBySearchnew(poly: string, Isrent: any, mnBedroom: any,mxBedroom: any, SPrice: any, EPrice: any,mnSize: any,mxSize: any,Floor: any, attr: string,UserID: string,pagesize : any,pagenumber:any) {
    let user = this._global.getAuthFromLocalStorage('localStorageUserDetails');
    if(user !=null){
    UserID = user.ID;
    }


    let prms = new HttpParams();
    prms = prms.append('polygon', poly);
    prms = prms.append('rent', Isrent);
    prms = prms.append('mnbedroom', mnBedroom);
    prms = prms.append('mxbedroom', mxBedroom);
    prms = prms.append('sprice', SPrice);
    prms = prms.append('eprice', EPrice);
    prms = prms.append('mnsize', mnSize);
    prms = prms.append('mxsize', mxSize);
    prms = prms.append('floor', Floor);
    prms = prms.append('attr', attr);
    prms = prms.append('UserID', UserID);
    prms = prms.append('pageno', pagenumber);
    prms = prms.append('pagesize', pagesize);
    // return this.http.get<VMProperty[]>(
    //     `${API_ROOT_URL}/api/Property/propertybymapwithParam`,
    //     { params: prms }
    // );
    return await this.http.get<any>(
      `${API_ROOT_URL}/api/Property/Get_PropertiesBySearchlist`,
      { params: prms }
  ).toPromise();
    // return this.http.post<VMProperty[]>( `${API_ROOT_URL}api/Property/propertybymap`, poly );
}


GetBoundedPropertyListBySearchall(poly: string, Isrent: any, mnBedroom: any,mxBedroom: any, SPrice: any, EPrice: any,mnSize: any,mxSize: any,Floor: any, attr: string,UserID: string): Observable<any> {
  let user = this._global.getAuthFromLocalStorage('localStorageUserDetails');
  if(user !=null){
  UserID = user.ID;
  }


  let prms = new HttpParams();
  prms = prms.append('polygon', poly);
  prms = prms.append('rent', Isrent);
  prms = prms.append('mnbedroom', mnBedroom);
  prms = prms.append('mxbedroom', mxBedroom);
  prms = prms.append('sprice', SPrice);
  prms = prms.append('eprice', EPrice);
  prms = prms.append('mnsize', mnSize);
  prms = prms.append('mxsize', mxSize);
  prms = prms.append('floor', Floor);
  prms = prms.append('attr', attr);
  prms = prms.append('UserID', UserID);
  
  // return this.http.get<VMProperty[]>(
  //     `${API_ROOT_URL}/api/Property/propertybymapwithParam`,
  //     { params: prms }
  // );
  return this.http.get<VMProperty[]>(
    `${API_ROOT_URL}/api/Property/Get_PropertiesBySearchall`,
    { params: prms }
);
  // return this.http.post<VMProperty[]>( `${API_ROOT_URL}api/Property/propertybymap`, poly );
}

    ////////////////////////////////////////////////////////
  ////////////// Favourite Property /////////////////////
  ///////////////////////////////////////////////////////


  PostPropertyFavourite(postData: any) {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    //const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<string>(`${API_ROOT_URL}/api/Property/postpropertyfavourite`, postData, headers);
  }

  GetAllFavouritePropertyById(): Observable<VMProperty[]> {
    let user = this._global.getAuthFromLocalStorage('localStorageUserDetails');
    let userId = user.ID;
    let prms = new HttpParams();
    prms = prms.append('userid', userId);
    console.log(userId);
    return this.http.get<VMProperty[]>(`${API_ROOT_URL}/api/Property/favpropertydetailsbyuser`, { params: prms });
}

   ////////////////////////////////////////////////////////
  ////////////// End Favourite Property /////////////////////
  ///////////////////////////////////////////////////////

}
