import { Component, OnInit } from '@angular/core';
import { VMSearch } from '../../../_VMClasses/search/searchObject';
import { ActivatedRoute, Router } from '@angular/router';
import { KTUtil } from '../../../../../assets/js/components/util';
import { PropertylistService } from 'src/app/core/services/PageServices/propertylist.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  user:any;
  _VMSearch:VMSearch
  constructor(private router : Router ,
    private propService: PropertylistService) {
    this._VMSearch=new VMSearch();
    //this._VMSearch = JSON.parse(localStorage.getItem('localStorageSearchDetails'));

    this.propService.searchFlag.subscribe(d=>{
      //this._VMAdvanceSearch = this.data;
      this._VMSearch = d;
   })
   }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('localStorageUserDetails'));
  }

  getFRent(){

      this._VMSearch.BuyRent="1";
      this._VMSearch.IsRent=true;
      this._VMSearch.EPrice = "10000";
      this.propService.setSearchFlag(this._VMSearch);
      this.propService.SearchFocused.next(true);
      //this._VMSearch.Location="Zürich";
      this.router.navigate(['/home']);
      this.scrollTop();
      //console.log("aa",this._VMSearch);

  }
  getFBuy(){
    this._VMSearch.BuyRent="0";
    this._VMSearch.IsRent=false;
    this._VMSearch.EPrice = "5000000";
    this.propService.setSearchFlag(this._VMSearch);
    this.propService.SearchFocused.next(true);
    //this._VMSearch.Location="Zürich";
    this.router.navigate(['/home']);
    this.scrollTop();
    //console.log("aa",this._VMSearch);
  }
  scrollTop() {
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
  }

}
