import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private httpLoading$ = new ReplaySubject<boolean>(1);
  constructor() { }

  httpProgress(): Observable<boolean> {
    return this.httpLoading$.asObservable();
  }

  setHttpProgressStatus(inprogess: boolean) {
    this.httpLoading$.next(inprogess);
  }
}

// import { Injectable } from '@angular/core';
// import { Observable, ReplaySubject } from 'rxjs';
// export enum LoaderType{
//   Default,
//   SidePan
// }
// @Injectable({
//   providedIn: 'root'
// })
// export class Loader{
//   public Loader: boolean;
//   public loaderType: LoaderType;
//   constructor() {
//     this.Loader = false;
//     this.loaderType=LoaderType.Default;
//    // this.httpLoading$ = new ReplaySubject<boolean>(1);
//   }
// }
// export class LoaderService {

//   private httpLoading$ = new ReplaySubject<Loader>(1);
//   constructor(private loader: Loader) {
//     this.loader= new Loader();
//   }

//   httpProgress(): Observable<Loader> {
//     return this.httpLoading$.asObservable();
//   }

//   setHttpProgressStatus(inprogess: Loader) {
//     this.httpLoading$.next(inprogess);
//   }
// }
