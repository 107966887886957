import { Injectable } from "@angular/core";


@Injectable({
  providedIn: 'root'
})

export class VMAttributeValuesModel {
  Selected: boolean;
  AttributeValues: VMAttributeValues;
  public constructor() {
    this.Selected = false;
    this.AttributeValues = new VMAttributeValues();
  }
}

// export class VMAttributeGroupModel {

//     AttributeGroup: VMAttributeValuesModel;
//     public constructor() {
//         this.AttributeGroup = new VMAttributeValuesModel();
//     }
// }

export class VMAttributesGroupModel {
  ID: number;
  ControlType: number;
  GroupName: string;
  IsEnabled: boolean;
  IsRequired: boolean;
  SelectionType: number;

  AttributeValuesModel: VMAttributeValuesModel[];
  public constructor(AttributeGroup: VMAttributeGroup) {

    this.ID = AttributeGroup.ID;
    this.ControlType = AttributeGroup.ControlType;
    this.GroupName = AttributeGroup.GroupName;
    this.IsEnabled = AttributeGroup.IsEnabled;
    this.IsRequired = AttributeGroup.IsRequired;
    this.SelectionType = AttributeGroup.SelectionType;

    this.AttributeValuesModel = [];

    AttributeGroup.AttributeValues.forEach(av => {
      let obj = new VMAttributeValuesModel();
      obj.Selected = false;
      obj.AttributeValues = av;
      this.AttributeValuesModel.push(obj);
    })
  }


}
export class AttributePostModel {
  AttributeValueID: number;
  PropertyID: string;
  Value: boolean;
  public constructor() {
    this.AttributeValueID = 0;
    this.PropertyID = '';
    this.Value = true;
  }
}

export class VMAttributeGroup {
  ID: number;
  ControlType: number;
  GroupName: string;
  IsEnabled: boolean;
  IsRequired: boolean;
  SelectionType: number;
  AttributeValues: VMAttributeValues[];

  public constructor() {
    this.AttributeValues = [];
  }

}

export class VMAttributeValues {
  GroupID: number;
  ID: number;
  IsEnabled: boolean;
  ValueName: string;
  IsRent: boolean;
  IsBuy: boolean;
}

export class Units {
  ID: string;
  UName: string;
}
export class Brochure {
  brochureName: string;
  Description: string;
}

export class BillingOption {
  BillingAddressID: string;
  ContactID: string;
  Title: string;
  Name: string;
  Email: string;
  Floor: string;
  City: string;
  Zip: string;
  Country: string;
  LanguageID: string;
  IsInvoiceEmail :boolean;
  IsInvoicePost :boolean;
  InvoiceLanguage :number;
  public constructor() {
    this.BillingAddressID = '0';
    this.ContactID='0';
    this.Title = 'M/S';
    this.Name = '';
    this.Email = '';
    this.Floor = '';
    this.City = '';
    this.Zip = '';
    this.Country = '';
    this.LanguageID = '';
    this.IsInvoiceEmail = true;
    this.IsInvoicePost = false;
  }
}
