import { Component, OnInit,Input,ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray,NgForm  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/core/services/GlobalServices/global.service';
import { PropertylistService } from 'src/app/core/services/PageServices/propertylist.service';
import { VMPropertyNotification } from '../../_VMClasses/propertylist/VMPropertyNotification';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contactagent',
  templateUrl: './contactagent.component.html',
  styleUrls: ['./contactagent.component.scss']
})
export class ContactagentComponent implements OnInit {
  @Input() public data;
  PropertyID: number;
  
  BASE_PATH: string;
  //contactagent: FormGroup;
 
  // datasource = {
  //   // propId: '',
  //   id: '',
  //   name: '',
  //   email: '',
  //   message: '',
  //   PropertyID: -1
  // };
  _VMNotification: VMPropertyNotification;
  @ViewChild('contactagent') contactagent: NgForm;
  constructor(private fb: FormBuilder,
    private propService: PropertylistService,
    private router: Router,
    private route: ActivatedRoute,
    private _global: GlobalService,
    private toastr: ToastrService,
    private _host: ElementRef) {
      
    this._VMNotification =new VMPropertyNotification();

  }
  // get f() {
  //   return this.contactagent.controls;
  // }

 initForm() {
  
 }
  ngOnInit(): void {
    const closetParent = (this._host.nativeElement).closest('agent-contact-form');
    console.log(this._host.nativeElement);
    console.log(closetParent);
    //console.log(this.data);
    
    //this.initForm();
  }

  submit() {

    // if(u){

    // }
    // console.log()
    console.log(this._VMNotification);
    this._VMNotification.PropertyID=this.data.ID;

    if (this.contactagent.invalid) {
      this.contactagent.form.markAllAsTouched();
      console.log("invalid");
      //this.scrollTop();
      
    }
    else{
        this.propService.postContactAgent(this._VMNotification).subscribe( (i: number)=>{
        console.log(i);
         if(i==1){
          this.toastr.success('Your message has been successfully send.We will contact you very soon!!', 'Thank You!');
        }
       else{
          this.toastr.error('Something Wrong!!', 'Sorry!');
           }
       });
      }
   
     //console.log(this.contactagent.value);
    
  }
  isControlHasError(controlName: any, validationType: string): boolean {
    //this.isStop=true;
    const control = controlName;
    if (!control) {
      return false;
      
    }
    
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    //console.log(result); 
    return result;
    
  }
}
