import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class VMProperty {
  ID: number;
  Description: string;
  Currency: Currency;
  Name: string;
  AddressID: string;
  UserID: string;
  Bedroom: number;
  Bathroom: number;
  TotalFloor: number;
  NumRoom: number;
  Size: number;
  SizeUnit: string;
  FloorNumber: number;
  FloorSpace: number;
  RoomHeight: number;
  Volume: number;
  YearRenovate: number;
  LotSize: number;
  YearBuild: number;
  Isavailable: any;
  AvailableFrom: string;
  AvailabilityStatus: string;
  slug: string;
  IsRent: boolean;
  RentPer: number;
  AddressMaster: VMAddress;
  ContactMaster: VMContactMaster;
  UserDetail: VMUserDetails;
  PropertyAdvertisementLogs: any;
  PropertyAttributeRelations: VMPropertyAttribute[];
  PropertyBrochures: VMPropertyBrochures[];
  PropertyFloorPlans: VMPropertyFloorPlan[];
  PropertyMediaRelations: VMPropertyMediaRelation[];
  AdvertisementPaymentRefs: VMAdvertisementPaymentRef[];
  PropertyNotifications: [] = [];
  PriceUnit: string;
  PriceFrom: string;
  FavCount:string;
  PropertyFavourites:VMPropertyFavourites[];
  public constructor() {
    this.ID = -1;
    this.Description = '';
    this.Currency = new Currency();
    this.Name = '';
    this.AddressID = '';
    this.UserID = '';
    this.Bedroom = 0;
    this.Bathroom = 0;
    this.NumRoom = 0;
    this.Size = 0;
    this.SizeUnit = '';
    this.FloorNumber = 0;
    this.YearBuild = 0;
    this.TotalFloor = 0;
    this.LotSize = 0;
    this.Isavailable = false;
    this.AvailableFrom = '';
    this.AvailabilityStatus = '';
    this.slug = '';
    this.IsRent = true;
    this.RentPer = 1;
    this.AddressMaster = new VMAddress();
    this.ContactMaster = new VMContactMaster();
    this.UserDetail=new VMUserDetails();
    this.PropertyAdvertisementLogs = [];
    this.PropertyAttributeRelations = [];
    this.PropertyBrochures = [];
    this.PropertyFloorPlans = [];
    this.PropertyMediaRelations = [];
    this.FavCount='';
  }
}
export class UserPropertyList{
  Proplist: VMProperty[];
  pagecount:number
}
export class VMAdvertisementPaymentRef {

  AddId: string;
  AddressMaster: VMAddress;

  ContactId: string;
  ContactMaster: VMContactMaster;

  ID: string;

  PropertyID: number;
  PackageTypeId: number;
  TotalNoOfOccurence: number;
  StartDate: Date;
  EndDate: Date;
  ExtendedDaysForRenewal: number;
  RenewalEndDate: Date;
  TotalPrice: number;
  CurrencyID: number;
  CurrentStatus: string;
  SuccessPayId: string;
  LiveStatus: string;
  CurrentFlag: boolean;
  OldPackageTypeID: number;
  OldStatus: string;
  OrderById: number;
  PropertyAdvertisementLogs: VMPropertyAdvertisementLog[];
  //AdvertisementPackage: VMAdvertisementPackages;
  Currency: Currency;

  InvoiceLanguage: number;
  IsInvoiceEmail :boolean;
  IsInvoicePost :boolean;
  public constructor() {
    this.PropertyAdvertisementLogs = [];
    this.ID = "0";

    this.LiveStatus = "Pending";
    this.StartDate = new Date();
    this.EndDate = new Date();
    this.RenewalEndDate = new Date();
    this.CurrentStatus = "PendingNew"
    //this.AdvertisementPackage = new VMAdvertisementPackages();
    this.Currency = new Currency();

    this.AddId = '0';
    this.AddressMaster = new VMAddress();

    this.ContactId = '0';
    this.ContactMaster = new VMContactMaster();

    this.InvoiceLanguage=12;
    this.IsInvoiceEmail=true;
    this.IsInvoicePost=false;
  }
  public setObject(obj: VMAdvertisementPaymentRef) {
    this.ID = obj.ID;

    this.AddId = obj.AddId;
    this.AddressMaster = obj.AddressMaster;

    this.ContactId = obj.ContactId;
    this.ContactMaster = obj.ContactMaster;

    this.PropertyID = obj.PropertyID;
    this.PackageTypeId = obj.PackageTypeId;
    this.TotalNoOfOccurence = obj.TotalNoOfOccurence;
    this.StartDate = new Date(obj.StartDate);
    this.EndDate = new Date(obj.EndDate);
    this.ExtendedDaysForRenewal = obj.ExtendedDaysForRenewal;
    this.RenewalEndDate = new Date(obj.RenewalEndDate);
    this.TotalPrice = obj.TotalPrice;
    this.CurrencyID = obj.CurrencyID;
    this.CurrentStatus = obj.CurrentStatus;
    this.SuccessPayId = obj.SuccessPayId;
    this.LiveStatus = obj.LiveStatus;
    this.CurrentFlag = obj.CurrentFlag;
    this.OldPackageTypeID = obj.OldPackageTypeID;
    this.OldStatus = obj.OldStatus;
    this.OrderById = obj.OrderById;
    this.PropertyAdvertisementLogs = obj.PropertyAdvertisementLogs;

    this.InvoiceLanguage=obj.InvoiceLanguage;
    this.IsInvoiceEmail=obj.IsInvoiceEmail;
    this.IsInvoicePost=obj.IsInvoicePost;
    //this.AdvertisementPackage = obj.AdvertisementPackage;
    this.Currency = obj.Currency;
  }
  public setNew(AdvertisementType: VMAdvertisementType,
    TotalNoOfOccurence: number,
    PropertyID: number, ExtendedDaysForRenewal: number) {
    if (this.ID === "0" || (this.ID !== "0" && this.LiveStatus === "Pending")) {
      this.PropertyAdvertisementLogs = [];

      this.AddId = '0';
      this.AddressMaster = new VMAddress();

      this.ContactId = '0';
      this.ContactMaster = new VMContactMaster();

      this.PropertyID = PropertyID;
      this.PackageTypeId = AdvertisementType.ID;
      this.TotalNoOfOccurence = TotalNoOfOccurence;

      // this.EndDate.setDate(this.StartDate.getDate() + TotalNoOfOccurence);
      this.ExtendedDaysForRenewal = ExtendedDaysForRenewal;
      // this.RenewalEndDate=;
      this.TotalPrice = 0;
      this.CurrencyID = AdvertisementType.AdvertisementPackages[0].CurrencyID;
      this.CurrentStatus = "PendingNew";
      this.SuccessPayId = "0";
      this.LiveStatus = "Pending";
      this.CurrentFlag = true;
      this.OldPackageTypeID = 0;
      this.OldStatus = "";
      this.OrderById = 0;
      let EndDt = new Date();

      AdvertisementType.AdvertisementPackages.forEach(AdvertPackage => {


        // AdvertPackage.PackageType AdvertisementPackages
        if (AdvertPackage.PackageType === 'Static') {
          let obj = new VMPropertyAdvertisementLog();
          obj.PackageId = AdvertPackage.ID;
          obj.NoOfOccurence = 1;
          obj.EndDate.setDate(this.StartDate.getDate() +
            (AdvertPackage.PackageDurationInDays * 1));

          EndDt.setDate(this.StartDate.getDate() +
            (AdvertPackage.PackageDurationInDays * 1));
          obj.Price = AdvertPackage.Price;
          obj.TotalPrice = AdvertPackage.Price * 1;
          this.TotalPrice += AdvertPackage.Price * 1;
          obj.AdvertisementPackage = AdvertPackage;
          this.PropertyAdvertisementLogs.push(obj);
        } else {
          if (TotalNoOfOccurence > 1) {
            let obj = new VMPropertyAdvertisementLog();
            obj.PackageId = AdvertPackage.ID;
            obj.NoOfOccurence = TotalNoOfOccurence - 1;
            obj.EndDate.setDate(this.StartDate.getDate() +
              (AdvertPackage.PackageDurationInDays * obj.NoOfOccurence));
            EndDt.setDate(this.StartDate.getDate() +
              (AdvertPackage.PackageDurationInDays * obj.NoOfOccurence));
            obj.Price = AdvertPackage.Price;
            obj.TotalPrice = AdvertPackage.Price * obj.NoOfOccurence;;
            this.TotalPrice += AdvertPackage.Price * obj.NoOfOccurence;
            obj.AdvertisementPackage = AdvertPackage;
            this.PropertyAdvertisementLogs.push(obj);
          }
        }



      })

      this.EndDate = EndDt;
      this.RenewalEndDate.setDate(this.EndDate.getDate() + this.ExtendedDaysForRenewal);

    } else if (this.ID !== "0" && this.LiveStatus === "CanRenew") {
      this.StartDate = new Date();
      if ((AdvertisementType.ID === this.OldPackageTypeID && this.SuccessPayId !== null) ||
        (AdvertisementType.ID === this.PackageTypeId && this.SuccessPayId === null)) {
        this.TotalPrice = 0;
        this.TotalNoOfOccurence = TotalNoOfOccurence;

        this.PackageTypeId = this.OldPackageTypeID;
        this.PropertyAdvertisementLogs = [];


        AdvertisementType.AdvertisementPackages.forEach(AdvertPackage => {
          let EndDt = new Date();
          if (AdvertPackage.PackageType === 'Float') {
            let obj = new VMPropertyAdvertisementLog();
            obj.PackageId = AdvertPackage.ID;
            obj.NoOfOccurence = TotalNoOfOccurence;
            obj.EndDate.setDate(this.StartDate.getDate() +
              (AdvertPackage.PackageDurationInDays * obj.NoOfOccurence));
            EndDt.setDate(this.StartDate.getDate() +
              (AdvertPackage.PackageDurationInDays * obj.NoOfOccurence));
            obj.Price = AdvertPackage.Price;
            obj.TotalPrice = AdvertPackage.Price * obj.NoOfOccurence;;
            this.TotalPrice += AdvertPackage.Price * obj.NoOfOccurence;
            obj.AdvertisementPackage = AdvertPackage;
            this.PropertyAdvertisementLogs.push(obj);
          }



        })

      } else {
        // this.PropertyAdvertisementLog = [];

        // this.PropertyID = PropertyID;
        this.PackageTypeId = AdvertisementType.ID;
        this.TotalNoOfOccurence = TotalNoOfOccurence;

        // this.EndDate.setDate(this.StartDate.getDate() + TotalNoOfOccurence);
        //this.ExtendedDaysForRenewal = ExtendedDaysForRenewal;
        // this.RenewalEndDate=;
        this.TotalPrice = 0;
        //this.CurrencyID = AdvertisementType.AdvertisementPackages[0].CurrencyID;
        this.CurrentStatus = "Changed";
        //this.SuccessPayId = "0";
        //this.LiveStatus = "Pending";
        this.CurrentFlag = true;
        //this.OldPackageID = 0;
        //this.OldStatus = "";
        //this.OrderById = 0;
        let EndDt = new Date();
        this.PropertyAdvertisementLogs = [];
        AdvertisementType.AdvertisementPackages.forEach(AdvertPackage => {



          if (AdvertPackage.PackageType === 'Static') {
            let obj = new VMPropertyAdvertisementLog();
            obj.PackageId = AdvertPackage.ID;
            obj.NoOfOccurence = 1;
            obj.EndDate.setDate(this.StartDate.getDate() +
              (AdvertPackage.PackageDurationInDays * 1));

            EndDt.setDate(this.StartDate.getDate() +
              (AdvertPackage.PackageDurationInDays * 1));
            obj.Price = AdvertPackage.Price;
            obj.TotalPrice = AdvertPackage.Price * 1;
            this.TotalPrice += AdvertPackage.Price * 1;
            obj.AdvertisementPackage = AdvertPackage;
            this.PropertyAdvertisementLogs.push(obj);
          } else {
            if (TotalNoOfOccurence > 1) {
              let obj = new VMPropertyAdvertisementLog();
              obj.PackageId = AdvertPackage.ID;
              obj.NoOfOccurence = TotalNoOfOccurence - 1;
              obj.EndDate.setDate(this.StartDate.getDate() +
                (AdvertPackage.PackageDurationInDays * obj.NoOfOccurence));
              EndDt.setDate(this.StartDate.getDate() +
                (AdvertPackage.PackageDurationInDays * obj.NoOfOccurence));
              obj.Price = AdvertPackage.Price;
              obj.TotalPrice = AdvertPackage.Price * obj.NoOfOccurence;;
              this.TotalPrice += AdvertPackage.Price * obj.NoOfOccurence;
              obj.AdvertisementPackage = AdvertPackage;
              this.PropertyAdvertisementLogs.push(obj);
            }
          }



        })

        this.EndDate = EndDt;
        this.RenewalEndDate.setDate(this.EndDate.getDate() + this.ExtendedDaysForRenewal);


      }

    }
  }
}
export class VMPropertyAdvertisementLog {
  ID: number;
  PackageId: number;
  StartDate: Date;
  EndDate: Date;
  NoOfOccurence: number;
  Price: number;
  TotalPrice: number;
  CurrencyID: number;
  AdvtPaytRef: string;
  AdvertisementPackage: VMAdvertisementPackages;
  public constructor() {
    this.ID = 0;
    this.PackageId = 0;
    this.StartDate = new Date();
    this.EndDate = new Date();
    this.NoOfOccurence = 0;
    this.Price = 0;
    this.TotalPrice = 0;
    this.CurrencyID = 0;
    this.AdvtPaytRef = "0";
    this.AdvertisementPackage = new VMAdvertisementPackages();
  }
}
export class VMContactMaster {
  Title: string;
  Email: string;
  Fax: string;
  ID: string;
  Mobile: string;
  Name: string;
  Phone: string;
}
export class VMAddress {
  ID: string;
  Address1: string;
  Address2: string;
  State: string;
  City: string;
  Country: string;
  Pin: string;
  Latitude: number;
  Longitude: number;

  public constructor() {
    this.ID = '';
    this.Address1 = '';
    this.Address2 = '';
    this.State = '';
    this.City = '';
    this.Pin = '';
    this.Latitude = 0;
    this.Longitude = 0;
  }
}
export class Currency {
  ID: number;
  Name: string;
  Code: string;
  Symbol: string;
  IsDefault: boolean;
  IsEnabled: boolean;
}
export class VMUserDetails {
  ID: string;
  FirstName: string;
  LastName: string;
  IsEnabled: boolean;
  AddressID: string;
  ContactID: string;
  AddressMaster: VMAddress;
  ContactMaster: VMContact;
  MediaID:string;
  Medium: VMMedium;
  public constructor() {
    this.ID = '';
    this.FirstName = '';
    this.LastName = '';
    this.IsEnabled = false;
    this.AddressID = '';
    this.ContactID = '';
    this.AddressMaster = new VMAddress();
    this.ContactMaster = new VMContact();
    this.MediaID='';
    this.Medium = new VMMedium();
  }
}
export class VMContact {
  ID: string;
  Name: string;
  Email: string;
  Phone: string;
  Mobile: string;
  Fax: string;
  UserDetails: VMUserDetails[];

  public constructor() {
    this.ID = '';
    this.Name = '';
    this.Email = '';
    this.Phone = '';
    this.Mobile = '';
    this.Fax = '';
    this.UserDetails = [];

  }
}

export class VMPropertyAttribute {
  ID: number;
  PropertyID: number;
  AttributeValueID: number;
  Value: boolean;
  AttributeValue: VMAttribute;

  public constructor() {
    this.ID = 0;
    this.PropertyID = 0;
    this.AttributeValueID = 0;
    this.Value = false;
    this.AttributeValue = new VMAttribute();

  }
}
export class VMAttribute {
  ID: number;
  ValueName: string;
  IsEnabled: boolean;
  GroupID: number;
  AttributeGroup: VMAttributeGroup;

  public constructor() {
    this.ID = 0;
    this.ValueName = '';
    this.IsEnabled = false;
    this.GroupID = 0;
    this.AttributeGroup = new VMAttributeGroup();

  }
}

export class VMAttributeGroup {
  ID: number;
  GroupName: string;
  IsEnabled: boolean;
  SelectionType: number;
  IsRequired: boolean;
  ControlType: number;

  public constructor() {
    this.ID = 0;
    this.GroupName = '';
    this.IsEnabled = false;
    this.SelectionType = 0;
    this.IsRequired = false;
    this.ControlType = 0;

  }
}

export class VMPropertyBrochures {
  ID: number;
  PropertyID: number;
  BrochureName: string;
  Description: string;
  MediaID: string;
  Medium: VMMedium;


  public constructor() {
    this.ID = 0;
    this.PropertyID = 0;
    this.BrochureName = '';
    this.Description = '';
    this.MediaID = '';
    this.Medium = new VMMedium();

  }
}
export class VMPropertyFloorPlan {
  ID: number;
  PropertyID: number;
  FloorNumber: string;
  Description: string;
  MediaID: string;
  Medium: VMMedium;


  public constructor() {
    this.ID = 0;
    this.PropertyID = 0;
    this.FloorNumber = '';
    this.Description = '';
    this.MediaID = '';
    this.Medium = new VMMedium();

  }
}

export class VMPropertyMediaRelation {
  ID: number;
  PropertyID: number;
  MediaID: string;
  Ordering : number;
  Medium: VMMedium;

  public constructor() {
    this.ID = 0;
    this.PropertyID = 0;
    this.MediaID = '';
    this.Ordering = 0;
    this.Medium = new VMMedium();

  }
}

export class VMMedium {
  ID: string;
  Name: string;
  Path: string;

  public constructor() {
    this.ID = '';
    this.Name = '';
    this.Path = '';

  }
}
export class VMPostPackage {
  ID: string;
  PropID: number;
  AdvertID: number;
  NoOfWeek: number;
  public constructor() {
    this.ID = '0';
  }
}
export class VMAdvertisementType {
  ID: number;
  Type: string;
  TypeDesc: string;
  IsRecomended: boolean;
  IsActive: boolean;
  AdvertisementPackages: VMAdvertisementPackages[];
  AdvertisementTypeFeatures: VMAdvertisementTypeFeatures[];
  public constructor() {
    this.AdvertisementPackages = [];
    this.AdvertisementTypeFeatures = [];
  }
}
export class VMAdvertisementPackages {
  ID: number;
  TypeID: number;
  PackageType: string;
  Price: number;
  CurrencyID: number;
  PackageDesc: string;
  PackageDurationUnit: string;
  PackageDurationInDays: number;
  IsActive: boolean;
  Currency: Currency;
  public constructor() {
    this.Currency = new Currency();
  }
}

export class VMAdvertisementTypeFeatures {
  ID: number;
  AdvrtTypeId: number;
  FeatureDesc: string;
  IsAvailable: boolean;
  IsVisible: boolean;
}
export class VMPropertyFavourites{
  Id: string
Property: string
PropertyId: string
UserDetail: string
UserId:string
public constructor() {
  this.Id = '';
  this.Property = '';
  this.PropertyId = '';
  this.UserDetail = '';
  this.UserId = '';
  
}
}