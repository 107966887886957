import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthModel, Language } from 'src/app/pages/_VMClasses/Global/Global';
//import { environment } from 'src/environments/environment.prod';
import { environment } from 'src/environments/environment';

const API_ROOT_URL = `${environment.apiUrl}`;
@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
  _languages: Language[] =[];
  private languages = new BehaviorSubject(this._languages);
  Languages: Observable<Language[]> = this.languages.asObservable();

  //language: Language[];
  constructor(private translateService: TranslateService, private http: HttpClient) {

  }

  changeLanguage(type: string): void {
    this.translateService.use(type);
    localStorage.setItem('langfordy',type);
  }

  setLanguage(): void {
    if(localStorage.getItem('langfordy') )
    {
      this.translateService.use(localStorage.getItem('langfordy'));
    }
    else{
    this.translateService.use('16');
    localStorage.setItem('langfordy','16');
    }
  }
  getAllLanguages(): any {
    // const userToken = this.authToken.access_token;
    // let httpHeaders = new HttpHeaders();
    // httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + userToken);
    // let prms = new HttpParams();
    // prms = prms.append('status', filterStatus);

    this.http.get<any>(`${API_ROOT_URL}/api/Language/GetLanguageFront`).subscribe(q=>{
      this.languages.next(q);
    });
  }

}
