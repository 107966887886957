import { Injectable } from '@angular/core';
import { AnyCnameRecord } from 'dns';
import { VMAttributeGroup } from '../propertylistAdmin/VMPropertyAdminModel';


@Injectable({
  providedIn: 'root'
})
export class PaymentGatways {
  ID: number;
  GatewayName: string;
  //public string APIKey { get; set; }
  //public string APISecret { get; set; }
  IsActive: boolean;
  IsEnabled: boolean;
  DefaultCurrency: number;
}
export class Language {
  ID: string;
  Code: string;
  Name: string;
  IsDefault: boolean;
  IsEnabled: boolean;
}

export class AuthModel {
  access_token: string;
  role: string;
  expires_in: Date;

  setAuth(auth: any) {
    this.access_token = auth.access_token;
    this.role = auth.role;
    this.expires_in = auth.expires_in;
  }
}

export class AlertOptions {
  autoClose: boolean;
  keepAfterRouteChange: boolean
  public constructor() {
    this.autoClose = true;
    this.keepAfterRouteChange = false
  }

};

export class SearchAttributes {
  $ID: string='';
  AttId: number=0;
  placeholder: string='';
  AttGrName: string='';
  AttributeGroup: VMAttributeGroup;
  selectedItems : any;
  dropdownSettings: any;
  public constructor() {
    this.AttributeGroup = new VMAttributeGroup();
  }
}

export class Data {
  $ID: string;
  ID: number;
  ValueName: string;
  GrName:string;

}
export class Attributes {
  placeholder: string;
  AttGrName: string;
  data: Data[];
  Selected: number = 0;
}
export class DropdownSettings {
  singleSelection: boolean;
  idField: string;
  textField: string;
  selectAllText: string;
  unSelectAllText: string;
  itemsShowLimit: number;
  allowSearchFilter: boolean
}

export class InternalRelation {
  dropdownSettings: DropdownSettings;
  Attributes: Attributes[];
  public constructor() {
    this.Attributes = [];
  }
}

export class ExternalRelations {
  AttGrName: string;
  attData: AttData[];
  public constructor() {
    this.attData = [];
  }
}
export class AttData {
  labelText: string;
  attVal: boolean;
}

export class PostSearchAttributes {
  dynamicAttribute: any;
  externalAttribute:ExternalAttribute[];
  internalAttribute:InternalAttribute[];
  public constructor() {
    this.dynamicAttribute = [];
    this.internalAttribute = [];
    this.externalAttribute = [];
  }
}

export class DynamicAttribute {
  AttId: number;
}
export class ExternalAttribute {
  AttId: number;
}
export class InternalAttribute {
  Name: string;
  ID:number
}

export class PriceRentBuy {
  constructor(public id: number, public name: string) { }
}
export class PriceRent {

  Rentpriceoption = [

    new PriceRentBuy(500, "500 CHF"),
    new PriceRentBuy(600, "600 CHF"),
    new PriceRentBuy(700, "700 CHF"),
    new PriceRentBuy(800, "800 CHF"),
    new PriceRentBuy(900, "900 CHF"),
    new PriceRentBuy(1000, "1000 CHF"),
    new PriceRentBuy(1100, "1,100 CHF"),
    new PriceRentBuy(1200, "1,200 CHF"),
    new PriceRentBuy(1300, "1,300 CHF"),
    new PriceRentBuy(1400, "1,400 CHF"),
    new PriceRentBuy(1500, "1,500 CHF"),
    new PriceRentBuy(1600, "1,600 CHF"),
    new PriceRentBuy(1700, "1,700 CHF"),
    new PriceRentBuy(1800, "1,800 CHF"),
    new PriceRentBuy(1900, "1,900 CHF"),
    new PriceRentBuy(2000, "2,000 CHF"),
    new PriceRentBuy(2200, "2,200 CHF"),
    new PriceRentBuy(2400, "2,400 CHF"),
    new PriceRentBuy(2600, "2,600 CHF"),
    new PriceRentBuy(2800, "2,800 CHF"),
    new PriceRentBuy(3000, "3,000 CHF"),
    new PriceRentBuy(3500, "3,500 CHF"),
    new PriceRentBuy(4000, "4,000 CHF"),
    new PriceRentBuy(4500, "4,500 CHF"),
    new PriceRentBuy(5000, "5,000 CHF"),
    new PriceRentBuy(5500, "5,500 CHF"),
    new PriceRentBuy(6000, "6,000 CHF"),
    new PriceRentBuy(7000, "7,000 CHF"),
    new PriceRentBuy(8000, "8,000 CHF"),
    new PriceRentBuy(9000, "9,000 CHF"),
    new PriceRentBuy(10000, "10,000 CHF"),
  ];
  Buypriceoption = [
    new PriceRentBuy(10000, "10,000 CHF"),
    new PriceRentBuy(20000, "20,000 CHF"),
    new PriceRentBuy(30000, "30,000 CHF"),
    new PriceRentBuy(40000, "40,000 CHF"),
    new PriceRentBuy(50000, "50,000 CHF"),
    new PriceRentBuy(100000, "100,000 CHF"),
    new PriceRentBuy(150000, "150,000 CHF"),
    new PriceRentBuy(200000, "200,000 CHF"),
    new PriceRentBuy(300000, "300,000 CHF"),
    new PriceRentBuy(400000, "400,000 CHF"),
    new PriceRentBuy(500000, "500,000 CHF"),
    new PriceRentBuy(600000, "600,000 CHF"),
    new PriceRentBuy(700000, "700,000 CHF"),
    new PriceRentBuy(800000, "800,000 CHF"),
    new PriceRentBuy(900000, "900,000 CHF"),
    new PriceRentBuy(1000000, "1,000,000 CHF"),
  ];
}
