import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../services/GlobalServices/loader-service.service';
const API_ROOT_URL = `${environment.apiUrl}`;
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  private count = 0;

  constructor(private loaderService: LoaderService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.count === 0) {
      if (req.url == `${API_ROOT_URL}/api/Property/Get_PropertiesBySearch`) {
        this.loaderService.setHttpProgressStatus(false);
      } else {
        this.loaderService.setHttpProgressStatus(false);
      }
    }
    this.count++;
    return next.handle(req).pipe(
      finalize(() => {
        this.count--;
        if (this.count === 0) {
          this.loaderService.setHttpProgressStatus(false);

        }
      }));
  }
}

// import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
// import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
// import { finalize } from 'rxjs/operators';
// import { Loader, LoaderService, LoaderType } from '../services/GlobalServices/loader-service.service';

// @Injectable()
// export class LoaderInterceptor implements HttpInterceptor {

//   private count = 0;

//   constructor(private loaderService: LoaderService, private loader: Loader) {
//     this.loader= new Loader();
//   }
//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     if (this.count === 0) {
//       if (req.url == 'http://imoapi.qr-dine.com/api/Property/propertybymap') {
//         this.loader.loaderType == LoaderType.SidePan;
//       }
//       else {
//         this.loader.loaderType == LoaderType.Default;
//       }
//       this.loader.Loader == true;
//         this.loaderService.setHttpProgressStatus(this.loader);
//     }
//     this.count++;
//     return next.handle(req).pipe(
//       finalize(() => {
//         this.count--;
//         if (this.count === 0) {
//           this.loader.loaderType == LoaderType.Default;
//           this.loader.Loader == false;
//           this.loaderService.setHttpProgressStatus(this.loader);
//         }
//       }));
//   }
// }
