import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { Currency, VMAdvertisementType, VMProperty } from 'src/app/pages/_VMClasses/propertylist/VMPropertyModel';
import { VMPropertyNotification } from 'src/app/pages/_VMClasses/propertylist/VMPropertyNotification';
import { VMAttributeGroup } from 'src/app/pages/_VMClasses/propertylistAdmin/VMPropertyAdminModel';
//import { environment } from 'src/environments/environment.prod';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../GlobalServices/global.service';

const API_ROOT_URL = `${environment.apiUrl}`;
const IMG_ROOT_URL = `${environment.imgUrl}`;
@Injectable({
  providedIn: 'root'
})
export class PropertylistAdminService {

  constructor(private http: HttpClient, private _global: GlobalService) { }

  getCurrency(): Observable<Currency[]> {
    return this.http.get<Currency[]>(`${API_ROOT_URL}/api/Currency/GetAllActiveCurrency`);
  }
  getAttributeGroups(): Observable<VMAttributeGroup[]> {
    // const userToken = this._global.getAuthFromLocalStorage('authLocalStorageToken');
    // let httpHeaders = new HttpHeaders();
    // httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + userToken);
    //http://imoapi.qr-dine.com/api/AttributeGroup
    return this.http.get<VMAttributeGroup[]>(`${API_ROOT_URL}/api/AttributeGroup`);
  }
  getPropertydetailsbypropid(propId): Observable<VMProperty> {
    // const userToken = this._global.getAuthFromLocalStorage('authLocalStorageToken');
    // let httpHeaders = new HttpHeaders();
    // httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + userToken);
    //http://imoapi.qr-dine.com/api/AttributeGroup
    let prms = new HttpParams();
    prms = prms.append('propid', propId);

    let user = this._global.getAuthFromLocalStorage('localStorageUserDetails');
    if(user !=null){
    prms = prms.append('Userid',  user.ID);
    }
    else{
      prms = prms.append('Userid',null);
    }
    //prms = prms.append('type', filterType);

    //return this.http.get<VMProperty>(`${API_ROOT_URL}/api/Property/propertydetailsbypropid`,
    //{ params: prms });

    ///////backup---13.12.21///////
    return this.http.get<VMProperty>(`${API_ROOT_URL}/api/Property/propertydetailsbypropidbackend`,
      { params: prms });
    ///////backup---13.12.21 end///////
    // return this.http.get<VMProperty>(`${API_ROOT_URL}/api/Property/propertydetailsbyid`,
    //   { params: prms });
  }

  getPropertyNotificationbypropid(propId): Observable<VMPropertyNotification> {
    // const userToken = this._global.getAuthFromLocalStorage('authLocalStorageToken');
    // let httpHeaders = new HttpHeaders();
    // httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + userToken);
    //http://imoapi.qr-dine.com/api/AttributeGroup
    let prms = new HttpParams();
    prms = prms.append('propid', propId);
    //prms = prms.append('type', filterType);

    return this.http.get<VMPropertyNotification>(`${API_ROOT_URL}/api/Property/propertynotificationbypropid`,
      { params: prms });
  }
  getPropertyUnreadNotificationbypropid(propId: string) {
    // const userToken = this._global.getAuthFromLocalStorage('authLocalStorageToken');
    // let httpHeaders = new HttpHeaders();
    // httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + userToken);
    //http://imoapi.qr-dine.com/api/AttributeGroup
    let prms = new HttpParams();
    prms = prms.append('propid', propId);
    //prms = prms.append('type', filterType);

    return this.http.get<string>(`${API_ROOT_URL}/api/Property/unreadpropertynotificationbypropid`,
      { params: prms });
  }
  UpdatePropertyNoification(postData: any) {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    //const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<string>(`${API_ROOT_URL}/api/Property/Updatepropertynotification`, postData, headers);
  }


  ////////////////////////////////////////////////////////
  ////////////// Strat: Post call : Step1 ////////////////
  ////////////////////////////////////////////////////////'Authorization': 'Bearer ' + userToken.access_token
  submitPropertyStep(_Property: VMProperty, Step: number) {

    if (Step == 1) {
      //const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
      const userToken = this._global.getAuthFromLocalStorage('authLocalStorageToken');
      //  let httpHeaders = new HttpHeaders();
      //  httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + userToken.access_token);
      //  httpHeaders.append('Content-Type' , 'application/json');
      var httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userToken.access_token
      });
      let PROPERTY_MODEL = this.propertyStepMap(_Property, Step);
      let data = JSON.stringify(PROPERTY_MODEL);

      return this.http.post<any>(`${API_ROOT_URL}/api/Property/PostProperty`, data, { headers: httpHeaders }).pipe(
        map((d) => {
          //You can perform some transformation here
          return d;
        }),
        catchError((err) => {
          //console.error(err);
          throw err;
        })
      );
    }
  }

  propertyStepMap(Propertydata: VMProperty, Step: number): any {

    if (Step == 1) {
      var ADDRESS_MODEL = {
        ID: Propertydata.AddressMaster.ID,
        Address1: Propertydata.AddressMaster.Address1,
        Address2: Propertydata.AddressMaster.Address2,
        State: Propertydata.AddressMaster.State,
        City: Propertydata.AddressMaster.City,
        Pin: Propertydata.AddressMaster.Pin,
        Latitude: Propertydata.AddressMaster.Latitude,
        Longitude: Propertydata.AddressMaster.Longitude
      };
      var CONTACT_MODEL = {
        ID: Propertydata.ContactMaster.ID,
        Name: Propertydata.ContactMaster.Name,
        Email: Propertydata.ContactMaster.Email,
        Phone: Propertydata.ContactMaster.Phone,
        Mobile: Propertydata.ContactMaster.Mobile,
        Fax: Propertydata.ContactMaster.Fax,

      };
      var PROPERTY_MODEL = {
        ID: Propertydata.ID == -1 ? 0 : Propertydata.ID,
        Name: Propertydata.Name,
        Description: Propertydata.Description,
        UserID: Propertydata.UserID,
        AddressID: Propertydata.AddressID,
        ContactID: Propertydata.ContactMaster.ID,
        Bedroom: Propertydata.Bedroom,
        Bathroom: Propertydata.Bathroom,
        NumRoom: Propertydata.NumRoom,
        Size: Propertydata.Size,
        SizeUnit: Propertydata.SizeUnit,
        PriceFrom: Propertydata.PriceFrom,
        PriceTo: "0",
        //PriceFrom: $('#_priceF').val(),
        //PriceTo: $('#_priceT').val(),
        //PriceUnit: $('#_PriceUnit').val(),
        PriceUnit: Propertydata.PriceUnit,
        slug: "",
        FloorNumber: Propertydata.FloorNumber,
        YearBuild: Propertydata.YearBuild,
        AvailableFrom: Propertydata.AvailableFrom,
        AvailabilityStatus: Propertydata.AvailabilityStatus,
        Isavailable: true,
        IsRent: Propertydata.IsRent,
        RentPer: Propertydata.RentPer,
        TotalFloor: Propertydata.TotalFloor,
        LotSize: Propertydata.LotSize,
        FloorSpace: Propertydata.FloorSpace,
        RoomHeight: Propertydata.RoomHeight,
        Volume: Propertydata.Volume,
        YearRenovate: Propertydata.YearRenovate,
        AddressMaster: ADDRESS_MODEL,
        ContactMaster: CONTACT_MODEL
      };


    }

    return PROPERTY_MODEL;
  }


  ////////////////////////////////////////////////////////
  /////////////// end: Post call : Step1 /////////////////
  ////////////////////////////////////////////////////////


  ////////////////////////////////////////////////////////
  ///////////// Strat: Images for galleary ///////////////
  ////////////////////////////////////////////////////////
  uploadImage(propid: string, files: File[],numbering:number, command: string) {
    //let prms = new HttpParams();
    const formData = new FormData();

    for (var i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    console.log(files);

    formData.append('propid', propid);
    formData.append('command', command);

    console.log(formData);

    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>(`${IMG_ROOT_URL}/api/Values/PostFront`,
      formData).pipe(
        map((res: any) => {
          var result1=[];
          console.log(res);
          for(var i=0;i<=res.length-1;i++){
            result1.push({"ID": res[i].ID,"Name": res[i].Name,"Path": res[i].Path, "PropertyMediaRelations": [{ "PropertyID": propid ,"Ordering": numbering+i+1 }]})
          }
          // const result = [{
          //   "ID": res.ID,
          //   "Name": res.Name,
          //   "Path": res.Path,
          //   "PropertyMediaRelations": [{ "PropertyID": propid }]
          // }]
          return result1;
        }),
        switchMap((response: any) => this.upload(
          response)),
        catchError((err) => {
          console.error('err', err);
          return of(err);
        }),
        finalize(() => { })
      );
    //, { headers: reqHeader }
  }
  upload(data): any {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>(`${API_ROOT_URL}/api/Property/postpropertygallery`, data);
  }
  deleteImg(propId: string, mediaID: string): Observable<number> {
    let prms = new HttpParams();
    prms = prms.append('PId', propId);
    prms = prms.append('mediaId', mediaID);

    return this.http.get<number>(`${API_ROOT_URL}/api/Property/deletepropertymediarelation`,
      { params: prms });
  }

  UpdatePropertynMedia(postData: any) {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    //const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<number>(`${API_ROOT_URL}/api/Property/Updatepropertymedia`, postData, headers);
  }
  ////////////////////////////////////////////////////////
  ///////////// End: Images for galleary /////////////////
  ////////////////////////////////////////////////////////


  ////////////////////////////////////////////////////////
  ///////////////// Strat: Floor Images //////////////////
  ////////////////////////////////////////////////////////

  onFloorImgSelect(propid: string,
    files: File[],
    command: string,
    FloorNumber: number
  ) {
    //let prms = new HttpParams();
    const formData = new FormData();

    for (var i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    console.log(files);

    formData.append('propid', propid);
    formData.append('command', command);

    console.log(formData);

    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>(`${IMG_ROOT_URL}/api/Values`,
      formData).pipe(
        map((res: any) => {
          const result = [
            {
              "PropertyID": propid,
              "FloorNumber": FloorNumber,
              "Medium": {
                "ID": res.ID,
                "Name": res.Name,
                "Path": res.Path
              }
            }]
          return result;
        }),
        switchMap((response: any) => this.uploadFloorImg(
          response)),
        catchError((err) => {
          console.error('err', err);
          return of(err);
        }),
        finalize(() => { })
      );
  }
  uploadFloorImg(data): any {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>(`${API_ROOT_URL}/api/Property/postpropertyfloorplan`, data);
  }
  deleteFloorImg(propId: string, mediaID: string): Observable<number> {
    let prms = new HttpParams();
    prms = prms.append('PId', propId);
    prms = prms.append('mediaId', mediaID);

    return this.http.get<number>(`${API_ROOT_URL}/api/Property/deletepropertyfloorimages`,
      { params: prms });
  }

  ////////////////////////////////////////////////////////
  ////////////////// End: Floor Images ///////////////////
  ////////////////////////////////////////////////////////


  ////////////////////////////////////////////////////////
  ////////////// Strat: Brochure Information /////////////
  ////////////////////////////////////////////////////////
  onBrochureSelect(propid: string,
    files: File[],
    command: string,
    brochureName: string,
    Description: string
  ) {
    //let prms = new HttpParams();
    const formData = new FormData();

    for (var i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    console.log(files);

    formData.append('propid', propid);
    formData.append('command', command);

    console.log(formData);

    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>(`${IMG_ROOT_URL}/api/Values`,
      formData).pipe(
        map((res: any) => {
          const result = [
            {
              "PropertyID": propid,
              "BrochureName": brochureName,
              "Description": Description,
              "Medium": {
                "ID": res.ID,
                "Name": res.Name,
                "Path": res.Path
              }
            }]
          return result;
        }),
        switchMap((response: any) => this.uploadBrochure(
          response)),
        catchError((err) => {
          console.error('err', err);
          return of(err);
        }),
        finalize(() => { })
      );
    //, { headers: reqHeader }
  }
  uploadBrochure(data): any {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any>(`${API_ROOT_URL}/api/Property/postpropertybrochure`, data);
  }
  deleteBrochure(propId: string, mediaID: string): Observable<number> {
    let prms = new HttpParams();
    prms = prms.append('PId', propId);
    prms = prms.append('mediaId', mediaID);
    return this.http.get<number>(`${API_ROOT_URL}/api/Property/deletepropertybrochure`,
      { params: prms });
  }
  ////////////////////////////////////////////////////////
  /////////////// End: Brochure Information //////////////
  ////////////////////////////////////////////////////////


  ////////////////////////////////////////////////////////
  ////////////// Strat: Attribute Information /////////////
  ////////////////////////////////////////////////////////
  postPropertyAttributes(data) {

    var httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let d = JSON.stringify(data);
    console.log(d);
    return this.http.post<any>(`${API_ROOT_URL}/api/Property/postpropertyattributeFront`, d, { headers: httpHeaders }).pipe(
      map((d) => {
        //You can perform some transformation here
        return d;
      }),
      catchError((err) => {
        //console.error(err);
        throw err;
      })
    );
  }
  deleteAttributeRelation(propId: string): Observable<number> {
    let prms = new HttpParams();
    prms = prms.append('PId', propId);

    return this.http.get<number>(`${API_ROOT_URL}/api/Property/deletepropertyattributerelation`,
      { params: prms });
  }
  ////////////////////////////////////////////////////////
  /////////////// End: Attribute Information //////////////
  ////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////
  ////////////// Strat: service package /////////////
  ////////////////////////////////////////////////////////
  getAllServicePackage(): Observable<VMAdvertisementType[]> {
    //const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
    // const userToken = this._global.getAuthFromLocalStorage('authLocalStorageToken');
    // //  let httpHeaders = new HttpHeaders();
    // //  httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + userToken.access_token);
    // //  httpHeaders.append('Content-Type' , 'application/json');
    // var httpHeaders = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': 'Bearer ' + userToken.access_token
    // });

    return this.http.get<VMAdvertisementType[]>(`${API_ROOT_URL}/api/Property/getAllServicePackage`).pipe(
      map((d) => {
        //You can perform some transformation here
        return d;
      }),
      catchError((err) => {
        //console.error(err);
        throw err;
      })
    );
  }

  postPackage(data) {

    const userToken = this._global.getAuthFromLocalStorage('authLocalStorageToken');
    //  let httpHeaders = new HttpHeaders();
    //  httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + userToken.access_token);
    //  httpHeaders.append('Content-Type' , 'application/json');
    var httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userToken.access_token
    });

    let d = JSON.stringify(data);
    console.log(d);
    return this.http.post<any>(`${API_ROOT_URL}/api/Property/postServicePackage`, d, { headers: httpHeaders });

    // .pipe(
    //   map((d) => {
    //     //You can perform some transformation here
    //     return d;
    //   }),
    //   catchError((err) => {
    //     //console.error(err);
    //     throw err;
    //   })
    // );
  }
  ////////////////////////////////////////////////////////
  /////////////// End: service package //////////////
  ////////////////////////////////////////////////////////


  
}
