import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthHttpService } from 'src/app/core/services/AuthServices/auth-http.service';
import { DynamicHeaderMenuService } from 'src/app/core/services/GlobalServices/header-menu.service';
import { LayoutService } from 'src/app/core/services/GlobalServices/layout.service';
import { PropertylistService } from 'src/app/core/services/PageServices/propertylist.service';
import { VMUserDetails } from 'src/app/pages/_VMClasses/propertylist/VMPropertyModel';
import { VMSearch } from 'src/app/pages/_VMClasses/search/searchObject';
import { NodeType } from '../../../configs/header-menu.config';
import { KTUtil } from '../../../../../../assets/js/components/util';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {
  _VMSearch:VMSearch
  subscriptions: Subscription[] = [];
  mConfig: any;
  mobileConfig: any;
  user: VMUserDetails;
  DropDownMenu:boolean;
  userRoleValidate: boolean;
  // nodeType: NodeType;
  public nodeType: typeof NodeType = NodeType;

  scrHeight:any;
  scrWidth:any;
  constructor(private menu: DynamicHeaderMenuService,
    private cdr: ChangeDetectorRef,
    private layoutService: LayoutService,
    private auth: AuthHttpService,
    private router: Router,
    private propService: PropertylistService) {
    this.getScreenSize();
    console.log("Resolution",this.scrHeight,this.scrWidth)

    this.auth.userRoleValidate.subscribe(r=>{
      this.userRoleValidate = r;
    })
    // this.auth.getUserByToken().subscribe(q => { this.user = q; });
    this.auth.currentUser$.subscribe(q => { this.user = q;console.log('user',this.user); });
    this.auth.dropDownMenu.subscribe(q=>{
      this.DropDownMenu=q;
    })
    console.log('userRoleValidate',this.userRoleValidate)
    this._VMSearch=new VMSearch();
    //this._VMSearch = JSON.parse(localStorage.getItem('localStorageSearchDetails'));

  
    // this.nodeType=NodeType.Root;
    //this.auth.currentUserSubject.asObservable();
    //this.auth.currentUserSubject.subscribe(q => { this.user = q; });
  }
  logout() {
    this.auth.logout();
  }
  ngOnInit(): void {
    this.propService.searchFlag.subscribe(d=>{
      //this._VMAdvanceSearch = this.data;
      this._VMSearch = d;
   })
    // menu load
    const menuSubscr = this.menu.menuConfig$.subscribe(res => {
      this.mConfig = res;
      this.mobileConfig = res;
      this.cdr.detectChanges();
    });
    this.subscriptions.push(menuSubscr);
    if(this.scrWidth <=920)
    {
      //let obj= this.mobileConfig.items.filter(q=>q.id == 8)
      //obj[0].title="Login or signup"
      this.menuorder(this.mobileConfig.items)
    }
    console.log(this.mConfig)
    console.log(this.mobileConfig)
  }
  linkClick(ch) {
    this.newMethod(ch);
    if (ch.title == 'Logout') {
      this.logout();
    } else {
      this.router.navigate([ch.page]);
    }
  }

  private newMethod(ch: any) {
    if (ch.title == 'RENT') {
      this._VMSearch.BuyRent = "1";
      this._VMSearch.IsRent = true;
      this._VMSearch.EPrice = "10000";
      this.propService.setSearchFlag(this._VMSearch);
      this.propService.SearchFocused.next(true);
      this.router.navigate([ch.page]);
      this.scrollTop();
    }
    if (ch.title == 'BUY') {
      this._VMSearch.BuyRent = "0";
      this._VMSearch.IsRent = false;
      this._VMSearch.EPrice = "5000000";
      this.propService.setSearchFlag(this._VMSearch);
      this.propService.SearchFocused.next(true);
      this.router.navigate([ch.page]);
      this.scrollTop();
    }
  }
  scrollTop() {
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 500);
  }

  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
   
    console.log(this.scrHeight, this.scrWidth);
}
sidebarclick(ch){
  if (ch.title == 'Logout') {
    this.logout();
  } 
  document.getElementById('bodyWrap').classList.remove('offcanvas-menu');
}
menuorder(arr){
  arr.sort(function (a, b) {
    return a.order - b.order;
  });
}
}
