<!-- Footer Start -->
<!--<footer>
    <div class="site-footer">
      <div class="container">
        <div class="row">
          <div class="col">
            <h5 class="heading-201 text-white h5 mb-4 mt-n3"><img src="../assets/images/footer-logo.png" alt="immotrade365 footer logo" /></h5>
            <ul class="list-unstyled">
              <li><a href="#">{{'ABOUTUS' | translate}}</a></li>
              <li><a href="#">{{'TESTIMONIALS' |translate}}</a></li>
              <li><a href="#">{{'TERMSOFSERVICE' | translate}}</a></li>
              <li><a href="#">{{'PRIVACY' | translate}}</a></li>
              <li><a href="#">{{'CONTACTUS' | translate}}</a></li>
            </ul>
          </div>
          <div class="col">
            <h5 class="heading-201 text-white h5 mb-4">{{'REALESTATE' | translate}}</h5>
            <ul class="list-unstyled">
              <li><a href="#">{{'ABOUTUS' | translate}}</a></li>
              <li><a href="#">{{'TESTIMONIALS' |translate}}</a></li>
              <li><a href="#">{{'TERMSOFSERVICE' | translate}}</a></li>
              <li><a href="#">{{'PRIVACY' | translate}}</a></li>
              <li><a href="#">{{'CONTACTUS' | translate}}</a></li>
            </ul>
          </div>
          <div class="col">
            <h5 class="heading-201 text-white h5 mb-4">{{'PRODUCTS' | translate}}</h5>
            <ul class="list-unstyled">
              <li><a href="#">{{'ABOUTUS' | translate}}</a></li>
              <li><a href="#">{{'TESTIMONIALS' |translate}}</a></li>
              <li><a href="#">{{'TERMSOFSERVICE' | translate}}</a></li>
              <li><a href="#">{{'PRIVACY' | translate}}</a></li>
              <li><a href="#">{{'CONTACTUS' | translate}}</a></li>
            </ul>
          </div>
          <div class="col">
            <h5 class="heading-201 text-white h5 mb-4">{{'DISCOVER' | translate}}</h5>
            <ul class="list-unstyled">
              <li><a href="#">{{'ABOUTUS' | translate}}</a></li>
              <li><a href="#">{{'TESTIMONIALS' |translate}}</a></li>
              <li><a href="#">{{'TERMSOFSERVICE' | translate}}</a></li>
              <li><a href="#">{{'PRIVACY' | translate}}</a></li>
              <li><a href="#">{{'CONTACTUS' | translate}}</a></li>
            </ul>
          </div>
          <div class="col">
            <h5 class="heading-201 text-white h5 mb-4">{{'SOCIALMEDIA' | translate}}</h5>
            <ul class="list-unstyled">
              <li><a href="#">Facebook</a></li>
              <li><a href="#">Twitter</a></li>
              <li><a href="#">Google pluus</a></li>
              <li><a href="#">Linkedin</a></li>
              <li><a href="#">Youtube</a></li>
            </ul>
          </div>
        </div>
      </div>
  </div>
  
  <div class="bottom-footer">
    <div class="container">
        <div class="row text-center">
          <div class="col-md-12">
            <div class="border-top pt-3">
              <p>&copy;<script>document.write(new Date().getFullYear());</script>&nbsp;Immotrade365. {{'ALLRIGHTSRESERVED' | translate}}.</p>
            </div>
          </div>

        </div>
    </div>
  </div>
  
</footer>-->
<!-- Footer End -->

<!-- Footer Start -->
<footer>
  <div class="site-footer">
    <div class="container">
    <div class="row">
      <div class="col-12 col-sm-6 col-lg-3">
      <h5 class="heading-201 text-white h5 mb-4 mt-n3"><img src="../assets/images/footer-logo.png" alt="immotrade365 footer logo" /></h5>
      <ul class="list-unstyled">
        <li><a href="#">{{'HOME' | translate}}</a></li>
        <li><a [routerLink]="['/about']">{{'ABOUTUS' | translate}}</a></li>
        <li><a [routerLink]="['/support']">{{'CONTACTUS/SUPPORT' | translate}}</a></li> <!--Contact / Support-->
        <!-- <li><a href="#">{{'NEWSLETTER' | translate}}</a></li>
        <li><a href="#">{{'NEWSROOM' | translate}}</a></li>
        <li><a href="#">{{'JOBS' | translate}}</a></li> -->
        <li><a [routerLink]="['/advertise']">{{'HOWTOADVERTISE' | translate}}</a></li>
        <li><a href="#">{{'LEGALINFORMATION' | translate}}</a></li>
        <li><a href="#">{{'SITEMAP' | translate}}</a></li>
      </ul>
      </div>
      <div class="col-12 col-sm-6 col-lg-3">
      <h5 class="heading-201 text-white h5 mb-4">{{'REALESTATE' | translate}}</h5>
      <ul class="list-unstyled">
        <li><a *ngIf="user" [routerLink]="['/createadd']">{{'SUBMITAD' | translate}}</a></li>
        <li><a *ngIf="!user" [routerLink]="['/account']">{{'SUBMITAD' | translate}}</a></li>
        <li><a [routerLink]=""  (click)="getFRent()">{{'RENT' | translate}}</a></li>
        <li><a [routerLink]=""  (click)="getFBuy()">{{'BUY' | translate}}</a></li>
        <!-- <li><a href="#">{{'MORTGAGES' | translate}}</a></li>
        <li><a href="#">{{'MOVING' | translate}}</a></li>
        <li><a href="#">{{'BLOG' | translate}}</a></li>
        <li><a href="#">{{'CATEGORIES' | translate}}</a></li> -->
      </ul>
      </div>
      <!-- <div class="col">
      <h5 class="heading-201 text-white h5 mb-4">{{'PRODUCTS' | translate}}</h5>
      <ul class="list-unstyled">
        <li><a href="#">{{'PLACEANADVERT' | translate}}</a></li>
        <li><a href="#">{{'SEARCHSUBSCRIPTION' | translate}}</a></li>
        <li><a href="#">{{'MOBILEAPPS' | translate}}</a></li>
        <li><a href="#">{{'ESTIMATEPROPERTY' | translate}}</a></li>
        <li><a href="#">{{'COMPAREMORTGAGES' | translate}}</a></li>
        <li><a href="#">{{'ONLINEMORTGAGE(GERMAN)' | translate}}</a></li>
        <li><a href="#">{{'ONLINEADVERTISING' | translate}}</a></li>
        <li><a href="#">{{'STARTSUPPORT' | translate}}</a></li>
      </ul>
      </div>
      <div class="col">
      <h5 class="heading-201 text-white h5 mb-4">{{'DISCOVER' | translate}}</h5>
      <ul class="list-unstyled">
        <li><a href="#">{{'STEPSTOHOMEOWNERSHIP' | translate}}</a></li>
        <li><a href="#">{{'HOMEDECORTIPS' | translate}}</a></li>
        <li><a href="#">{{'NEWSLETTER' | translate}}</a></li>
      </ul>
      </div> -->
      <div class="col-12 col-sm-6 col-lg-3">
      <h5 class="heading-201 text-white h5 mb-4">{{'SOCIALMEDIA' | translate}}</h5>
      <ul class="list-unstyled">
        <li><a href="#">{{'FACEBOOK' | translate}}</a></li>
        <li><a href="#">{{'INSTAGRAM' | translate}}</a></li>
        <!-- <li><a href="#">{{'TWITTER' | translate}}</a></li>
        <li><a href="#">{{'YOUTUBE' | translate}}</a></li>
        <li><a href="#">{{'LINKEDIN' | translate}}</a></li>
        <li><a href="#">{{'XING' | translate}}</a></li> -->
      </ul>
      </div>
      <div class="col-12 col-sm-6 col-lg-3">
        <h5 class="heading-201 text-white h5 mb-4">App Download</h5>
        <ul class="list-unstyled pt-2">
          <li class=""><a href="#" target="_blank"><img alt="App Store" src="../assets/images/appstore.svg"></a></li>
          <li class="mt-3"><a href="#" target="_blank"><img alt="Google Play" src="../assets/images/googleplay.svg"></a></li>
        </ul>
      </div>
    </div>
    </div>
  </div>
  
  <div class="bottom-footer">
  <div class="container">
    <div class="row text-center">
      <div class="col-md-12">
      <div class="border-top pt-2">
        <p class="mb-2">&copy;<script>document.write(new Date().getFullYear());</script>&nbsp;Immotrade365. All rights reserved.</p>
      </div>
      </div>

    </div>
  </div>
  </div>
  
  </footer><!-- Footer End -->

