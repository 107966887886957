<!-- <div [class.hidden]="!show">
  <div class="loader-overlay">
    <div *ngIf="show" class="spinner"></div>
  </div>
</div> -->


<app-header></app-header>
<router-outlet></router-outlet>

 <!-- <app-footer></app-footer>  -->
