import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateConfigService } from 'src/app/core/services/GlobalServices/translate-config.service';
import { Language } from 'src/app/pages/_VMClasses/Global/Global';

interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}
@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  deflang:number=16;
  language: Language;
  languages: Language[] = [];
  constructor(private translateService: TranslateConfigService,
    private cdr: ChangeDetectorRef) {
    this.language = new Language();
  }

  ngOnInit(): void {
    this.translateService.setLanguage();
    this.translateService.getAllLanguages();
    // 16

    this.translateService.Languages.subscribe((arg: Language[]) => {


      this.languages = arg;
      this.languages.forEach((lang: Language) => {
        if(localStorage.getItem('langfordy') ){
         if(lang.ID == localStorage.getItem('langfordy')){
          this.language = lang;
          this.translateService.changeLanguage(lang.ID);

         }
        
        }

        else  {
          if((lang.IsDefault))
          {
            this.language = lang;
            this.translateService.changeLanguage(lang.ID);
          }
         
          
        }
      });
    }
    );
    // this.translateService.getAllLanguages().subscribe((arg: Language[]) => {


    //   this.languages = arg;
    //   this.languages.forEach((lang: Language) => {
    //     if (lang.IsDefault) {
    //       this.language = lang;
    //       this.translateService.changeLanguage(lang.ID);
    //     } else {

    //     }
    //   });
    // }
    // );


  }

  setLanguageWithRefresh(lang): void {
    this.language = lang;
    //this.translateService.changeLanguage(lang.target.value);
     this.translateService.changeLanguage(lang.ID);
    //window.location.reload();
    document.getElementById('bodyWrap').classList.remove('offcanvas-menu');

  }
}
