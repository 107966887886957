import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public IsTransparent = new BehaviorSubject(false);
  public isTransparent: Observable<boolean> = this.IsTransparent.asObservable();

  public IsShowDiv  = new BehaviorSubject(false);
  public isShowDiv : Observable<boolean> = this.IsShowDiv.asObservable();

  public IsFooterShow = new BehaviorSubject(false);
  public isFooterShow: Observable<boolean> = this.IsFooterShow.asObservable();

  constructor() { }

  setBgColorProp(value: boolean): void{
    this.IsTransparent.next(value);
  }
  setfootershowhide(value: boolean): void{
    this.IsFooterShow.next(value);
  }
}
