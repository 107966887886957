// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,


 apiUrl: 'https://api.immotrade365.com/',
  imgUrl: 'https://imageserverapi.immotrade365.com/',     //'http://fileserver.infrastep.com/'
  //appUrl: 'https://localhost:4200/',
  appUrl: 'https://immotrade365.com/',
 // googleMapApi: 'AIzaSyCYmkQTlTfTbCHH35cAl7uzMRmP2MU0TC8'
    googleMapApi: 'AIzaSyBT9w3qni3AMZRrcFkCKnkbRduhVIwm4_c'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
